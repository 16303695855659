import React from 'react';

import { Alert, ClipboardButton, Field, HorizontalGroup, Input, QueryField } from '@grafana/ui';
import { AddApiKeyResult } from 'graphql/mutations';

interface Props {
  apiKey: AddApiKeyResult;
}

export const AddApiKeyResponse: React.FC<Props> = ({ apiKey }) => {
  return (
    <>
      <Field label="Key">
        <HorizontalGroup>
          <Input value={apiKey.addApiKey.key} width={75.25} disabled />
          <ClipboardButton icon="copy" variant="primary" getText={() => apiKey.addApiKey.key}>
            Copy
          </ClipboardButton>
        </HorizontalGroup>
      </Field>
      <Alert title="You will only be able to view this key here once!" severity="info">
        It is not stored in this form, so be sure to copy it now.
      </Alert>
      <p>You can authenticate a request using the X-API-Key HTTP header, example:</p>
      <QueryField
        query={`curl -H "X-API-Key: ${apiKey.addApiKey.key}" https://api.ecomate.cloud/data/v1/vessels`}
        portalOrigin={''}
        disabled
      />
      <p style={{ marginTop: '10px' }}>
        For API specification, see{' '}
        <a style={{ color: '#6FA8DC' }} href="https://api.ecomate.cloud/data/swagger">
          API documentation
        </a>
      </p>
    </>
  );
};
