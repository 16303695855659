import React, { useState, useEffect, useCallback } from 'react';

import { SelectableValue, TimeZone } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Button, Field, FieldSet, Form, RadioButtonGroup, TimeZonePicker, WeekStartPicker } from '@grafana/ui';

const themes: SelectableValue[] = [
  { value: '', label: 'Default' },
  { value: 'dark', label: 'Dark' },
  { value: 'light', label: 'Light' },
];

export interface Props {
  resourceUri: string;
  disabled?: boolean;
}

export const UserPreferencesGrafana: React.FC<Props> = ({ resourceUri, disabled }) => {
  const [theme, setTheme] = useState('');
  const [timezone, setTimezone] = useState<TimeZone>('');
  const [weekStart, setWeekStart] = useState('');

  const backendSrv = getBackendSrv();

  useEffect(() => {
    const loadService = async () => {
      const preferences = await backendSrv.get(`/api/${resourceUri}/preferences`);
      setTheme(preferences.theme);
      setTimezone(preferences.timezone);
      setWeekStart(preferences.weekStart);
    };
    loadService();
  }, [resourceUri, backendSrv]);

  const onSubmitForm = useCallback(() => {
    const call = async () => {
      await backendSrv.put(`/api/${resourceUri}/preferences`, { theme, timezone, weekStart });
      window.location.reload();
    };
    call();
  }, [resourceUri, backendSrv, theme, timezone, weekStart]);

  return (
    <Form onSubmit={onSubmitForm}>
      {() => {
        return (
          <FieldSet label={'Preferences'} disabled={disabled}>
            <Field label={'UI Theme'}>
              <RadioButtonGroup
                options={themes}
                value={themes.find((item) => item.value === theme)?.value}
                onChange={setTheme}
              />
            </Field>

            <Field label={'Timezone'}>
              <TimeZonePicker
                includeInternal={true}
                value={timezone}
                onChange={(timezone) => {
                  if (!timezone) {
                    return;
                  }
                  setTimezone(timezone);
                }}
                inputId="shared-preferences-timezone-picker"
              />
            </Field>

            <Field label={'Week start'}>
              <WeekStartPicker
                value={weekStart}
                onChange={setWeekStart}
                inputId={'shared-preferences-week-start-picker'}
              />
            </Field>

            <div className="gf-form-button-row">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </FieldSet>
        );
      }}
    </Form>
  );
};
