import React, { useMemo } from 'react';
import { AppRootProps } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { UserFragmentResult } from 'graphql/fragments';
import { GetUserResult, GET_USER } from 'graphql/queries';
import useSettings from 'setting-hooks';
import { useEcomateApi } from 'ecomate-hooks';
import { NavigationBar } from 'components/NavigationBar';
import { Pages } from 'pages/Pages';
import { CompanyManagementAppSettings, CompanyOptions, NavigationItem } from 'types';
import { UsersPage } from 'pages/UsersPage';
import { GroupsPage } from 'pages/GroupsPage';
import { SettingsPage } from 'pages/SettingsPage';
import { ApiKeysPage } from 'pages/ApiKeysPage';

const AppProvider = (props: AppRootProps) => {
  const { meta } = props;
  const [settings] = useSettings<CompanyManagementAppSettings>(meta.id);

  const [Provider] = useEcomateApi(settings?.datasource ? settings?.datasource + '/company/graphql' : undefined);

  if (!Provider) {
    return <LoadingPlaceholder text="Loading..." />;
  }
  return (
    <Provider>
      <App settings={settings} />
    </Provider>
  );
};

const App = (props: { settings?: CompanyManagementAppSettings }) => {
  const { settings } = props;
  const { url } = useRouteMatch();
  const location = useLocation();

  const user = useQuery<GetUserResult>(GET_USER);

  const active = useMemo(() => {
    if (location.pathname === `${url}/groups` || location.pathname === `${url}/groups/`) {
      return 'groups';
    }
    if (location.pathname === `${url}/users` || location.pathname === `${url}/users/`) {
      return 'users';
    }
    if (location.pathname === `${url}/apikeys` || location.pathname === `${url}/apikeys/`) {
      return 'apikeys';
    }
    return 'settings';
  }, [location, url]);

  const Content = useMemo(() => {
    const items: NavigationItem[] = [
      {
        label: 'Settings',
        url: url,
        icon: 'cog',
        access: 'RESTRICTED_VIEWER',
        active: active === 'settings',
        page: <SettingsPage resourceUri="user" isAdmin={user.data?.user?.role === 'ADMINISTRATOR'} />,
      },
      {
        label: 'Users',
        url: url + '/users',
        icon: 'users-alt',
        access: 'ADMINISTRATOR',
        active: active === 'users',
        page: <UsersPage />,
      },
      {
        label: 'Vessel groups',
        url: url + '/groups',
        icon: 'layer-group',
        access: 'ADMINISTRATOR',
        active: active === 'groups',
        page: <GroupsPage />,
      },
      {
        label: 'API Keys',
        url: url + '/apikeys',
        icon: 'key-skeleton-alt',
        access: 'ADMINISTRATOR',
        active: active === 'apikeys',
        page: <ApiKeysPage />,
        requireOption: 'enableApiKeys',
      },
    ];

    const itemsWithAccess = items.map((item) => {
      return {
        ...item,
        hasAccess: hasAccess(settings?.options, user.data?.user, item),
      };
    });
    const accessibleItems = itemsWithAccess.filter((item) => item.hasAccess);
    return (
      <>
        <div className="page-container">
          <div className="page-header">
            <div className="page-header__inner">
              <span className="page-header__logo">
                <img
                  className="page-header__img"
                  src="public/plugins/krohnemarine-companymanagement-app/img/logo.svg"
                />
              </span>
              <div className="page-header__info-block">
                <h1 className="page-header__title">EcoMATE Cloud Administration</h1>
                <div className="page-header__sub-title">Manage user settings</div>
              </div>
            </div>
            <NavigationBar items={accessibleItems} />
          </div>
        </div>
        <div className="page-container page-body">
          {user.loading ? <LoadingPlaceholder text="Loading..." /> : <Pages items={itemsWithAccess} />}
        </div>
      </>
    );
  }, [active, url, settings?.options, user.loading, user.data?.user]);

  return <>{Content}</>;
};

export function hasAccess(
  appOptions: CompanyOptions | undefined,
  user: UserFragmentResult | undefined,
  item: NavigationItem
): boolean {
  if (item.internal && !user?.isInternal) {
    return false;
  }
  if (item.requireOption && (!appOptions || appOptions[item.requireOption] !== true)) {
    return false;
  }
  switch (item.access) {
    case 'ADMINISTRATOR':
      return user?.role === 'ADMINISTRATOR';
    case 'VIEWER':
      return user?.role === 'ADMINISTRATOR' || user?.role === 'VIEWER';
    case 'RESTRICTED_VIEWER':
      return true;
    case undefined:
      return true;
    default:
      return false;
  }
}

export default AppProvider;
