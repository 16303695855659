import React, { useState } from 'react';
import { usePaging } from 'ecomate-hooks';
import { Button, ConfirmModal, HorizontalGroup, Pagination, RadioButtonGroup } from '@grafana/ui';
import { User } from 'types';
import { UsersGroupToolbar } from './UsersGroupToolbar';
import { useAppNotification } from 'notifications';
import { useMutation } from '@apollo/client';
import {
  AddUsersToVesselGroupResult,
  AddUsersToVesselGroupVariables,
  ADD_USERS_TO_VESSEL_GROUP,
  RemoveUsersFromVesselGroupResult,
  RemoveUsersFromVesselGroupVariables,
  REMOVE_USERS_FROM_VESSEL_GROUP,
} from 'graphql/mutations';
import { VesselGroupWithVesselsFieldsResult } from 'graphql/fragments';

interface Props {
  groups: VesselGroupWithVesselsFieldsResult[];
  user: User;
}

export const UsersGroupList: React.FC<Props> = ({ groups, user }) => {
  const notifier = useAppNotification();

  const [addGroup] = useMutation<AddUsersToVesselGroupResult, AddUsersToVesselGroupVariables>(
    ADD_USERS_TO_VESSEL_GROUP,
    {
      onError() {
        notifier.error('Failed to give user access to group');
      },
      update(cache, { data }) {
        if (data) {
          notifier.success(`Added access to ${data.addUsersToGroup.name || 'group'} for ${user.login}`);
          cache.modify({
            id: cache.identify({ ...user }),
            fields: {
              vesselGroups() {
                return [...(user.vesselGroups || []), data.addUsersToGroup];
              },
            },
          });
        }
      },
    }
  );

  const [removeGroup] = useMutation<RemoveUsersFromVesselGroupResult, RemoveUsersFromVesselGroupVariables>(
    REMOVE_USERS_FROM_VESSEL_GROUP,
    {
      onError: () => {
        notifier.error(`Failed to revoke access to group`);
      },
      update(cache, { data }) {
        if (data) {
          notifier.success(`Revoked access to ${data.removeUsersFromGroup.name || 'group'} for ${user.login}`);
          cache.modify({
            id: cache.identify({ ...user }),
            fields: {
              vesselGroups() {
                return user.vesselGroups?.filter((e) => e.id !== data.removeUsersFromGroup.id);
              },
            },
          });
        }
      },
    }
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [groupToRemove, setGroupToRemove] = useState<VesselGroupWithVesselsFieldsResult | null>(null);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: groups,
    pageSize,
    searchQuery,
    options: {
      keys: ['name'],
      sort: (a, b) => a.name?.localeCompare(b.name || '') || 0,
    },
  });

  return (
    <>
      <UsersGroupToolbar
        placeholder="Search groups by name"
        onAdd={(groups) => {
          if (groups.length && user.id) {
            groups.forEach((group) => {
              if (group.id) {
                addGroup({ variables: { groupId: group.id, ids: [user.id] } });
              }
            });
          }
        }}
        onSearch={setSearchQuery}
        initialSearchQuery={''}
        user={user}
        canAdd
      />
      <table className="filter-table form-inline">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Vessels</th>
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {entities.map((group) => (
            <tr key={group.id}>
              <td className="max-width-8">{group.name}</td>
              <td className="max-width-10" style={{ overflowX: 'clip' }}>
                {group.description}
              </td>
              <td className="width-1">{group.vessels?.length}</td>
              <td>
                <Button
                  variant="destructive"
                  size="sm"
                  icon="times"
                  onClick={() => {
                    setGroupToRemove(group);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <HorizontalGroup justify="space-between" align="flex-start">
        {groups.length > 10 && (
          <HorizontalGroup>
            <span>Page size:</span>
            <RadioButtonGroup
              options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
              onChange={setPageSize}
              value={pageSize}
              size="sm"
            />
          </HorizontalGroup>
        )}
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>
      {Boolean(groupToRemove) && (
        <ConfirmModal
          isOpen={Boolean(groupToRemove)}
          title="Revoke access"
          body={`Are you sure you want to revoke access to group ${groupToRemove?.name}?`}
          confirmText="Revoke"
          onConfirm={() => {
            removeGroup({ variables: { groupId: groupToRemove?.id || 0, ids: [user.id] } });
            setGroupToRemove(null);
          }}
          onDismiss={() => {
            setGroupToRemove(null);
          }}
        />
      )}
    </>
  );
};
