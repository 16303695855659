import React, { useState } from 'react';
import { Button, Modal, Form, Field, Input, Select, InputControl, InlineSwitch, HorizontalGroup } from '@grafana/ui';
import { User, UserRole } from 'types';

import { AddUserVariables } from 'graphql/mutations';
import { Roles } from './UsersTable';
import { SelectableValue } from '@grafana/data';
import { Toolbar, ToolbarProps } from './Toolbar';

interface Props extends Omit<ToolbarProps, 'onAdd'> {
  onAdd: (user: Partial<User & { sendEmail: boolean }>) => void;
}

export const UsersToolbar: React.FC<Props> = (props) => {
  const { onAdd, canAdd } = props;

  const [userToAdd, setUserToAdd] = useState<Partial<User> | null>(null);

  return (
    <>
      <Toolbar {...props} onAdd={() => setUserToAdd({})} addText="Add user" canAdd={canAdd} />
      {Boolean(userToAdd) && (
        <Modal title="Add user" isOpen onDismiss={() => setUserToAdd(null)}>
          <Form
            onSubmit={(user: Partial<Omit<AddUserVariables, 'role'> & { role: SelectableValue<UserRole> }>) => {
              onAdd({ ...user, role: user.role?.value });
              setUserToAdd(null);
            }}
          >
            {({ register, control, errors }) => (
              <>
                <Field label="E-mail" invalid={!!errors.email} error="E-mail not valid">
                  <Input placeholder="E-mail" {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                </Field>
                <Field label="Role">
                  <InputControl
                    render={({ field }) => <Select {...field} options={Roles} />}
                    name="role"
                    control={control}
                  />
                </Field>
                <Field>
                  <HorizontalGroup align="flex-end" spacing="md">
                    <InlineSwitch
                      label="Send invite"
                      showLabel={true}
                      defaultChecked={true}
                      {...register('sendEmail', { required: false })}
                    />
                    <Button type="submit">Add</Button>
                  </HorizontalGroup>
                </Field>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
