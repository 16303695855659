import React, { useState } from 'react';
import { Button, ConfirmModal, HorizontalGroup, Pagination, RadioButtonGroup } from '@grafana/ui';
import { BaseUser, isDefined, VesselGroup } from 'types';
import { useMutation } from '@apollo/client';
import {
  AddUsersToVesselGroupResult,
  AddUsersToVesselGroupVariables,
  ADD_USERS_TO_VESSEL_GROUP,
  RemoveUsersFromVesselGroupResult,
  RemoveUsersFromVesselGroupVariables,
  REMOVE_USERS_FROM_VESSEL_GROUP,
} from 'graphql/mutations';
import { useAppNotification } from 'notifications';
import { userSort } from 'pages/UsersPage';
import { getContextSrv, usePaging } from 'ecomate-hooks';
import { GroupUsersToolbar } from './GroupUsersToolbar';

interface Props {
  group: VesselGroup;
}

export const UserList: React.FC<Props> = ({ group }) => {
  const notifier = useAppNotification();
  const currentUser = getContextSrv()?.user;
  const [addUser] = useMutation<AddUsersToVesselGroupResult, AddUsersToVesselGroupVariables>(
    ADD_USERS_TO_VESSEL_GROUP,
    {
      onError() {
        notifier.error('Failed to grant access to vessel group');
      },
      update(cache, { data }) {
        if (data) {
          let newUsers = data.addUsersToGroup.users?.filter((e) => !group.users?.find((u) => u.id === e.id));
          if (newUsers?.length === 1) {
            notifier.success(`Added ${newUsers[0]?.login || 'user'} to ${data.addUsersToGroup.name}`);
          } else {
            notifier.success(`Added ${newUsers?.length + ' ' ?? ''}users for ${data.addUsersToGroup.name}`);
          }
          cache.modify({
            id: cache.identify({ ...data.addUsersToGroup }),
            fields: {
              users() {
                return data.addUsersToGroup.users;
              },
            },
          });
        }
      },
    }
  );

  const [removeUser] = useMutation<RemoveUsersFromVesselGroupResult, RemoveUsersFromVesselGroupVariables>(
    REMOVE_USERS_FROM_VESSEL_GROUP,
    {
      onCompleted: (e) => {
        notifier.success(`Removed user from vessel group ${e.removeUsersFromGroup.name}`);
      },
      onError: () => {
        notifier.error(`Failed to remove user from group`);
      },
      update(cache, { data }) {
        if (data) {
          cache.modify({
            id: cache.identify({ ...data.removeUsersFromGroup }),
            fields: {
              vessels() {
                return data.removeUsersFromGroup.vessels;
              },
            },
          });
        }
      },
    }
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [userToRemove, setUserToRemove] = useState<BaseUser | null>(null);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: group.users || [],
    pageSize,
    searchQuery,
    options: {
      keys: ['login', 'email'],
      sort: userSort(currentUser?.id || -1),
    },
  });

  return (
    <>
      <GroupUsersToolbar
        placeholder="Search for user by login, email or id"
        onAdd={(users) => {
          if (users.length && group.id) {
            addUser({ variables: { ids: users.map((e) => e.id).filter(isDefined), groupId: group.id } });
          }
        }}
        onSearch={setSearchQuery}
        initialSearchQuery={''}
        existingUsers={group.users}
        canAdd
      />
      <table className="filter-table form-inline" style={{ marginBottom: '4px' }}>
        <thead>
          <tr>
            <th>Login</th>
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {entities.map((user) => (
            <tr key={user.id}>
              <td>{user.login}</td>
              <td>
                <Button
                  variant="destructive"
                  size="sm"
                  icon="times"
                  onClick={() => {
                    setUserToRemove(user);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <HorizontalGroup justify="space-between" align="flex-start">
        {group.users && group.users.length > 10 && (
          <HorizontalGroup>
            <span>Page size:</span>
            <RadioButtonGroup
              options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
              onChange={setPageSize}
              value={pageSize}
              size="sm"
            />
          </HorizontalGroup>
        )}
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>
      {Boolean(userToRemove) && (
        <ConfirmModal
          isOpen={Boolean(userToRemove)}
          title="Revoke access to group"
          body={`Are you sure you want to revoke access to ${group.name || 'group'} from ${userToRemove?.login}?`}
          confirmText="Revoke"
          onConfirm={() => {
            if (userToRemove?.id && group.id) {
              removeUser({ variables: { groupId: group.id, ids: [userToRemove!.id] } });
              setUserToRemove(null);
            } else {
              notifier.warning('User and/or group not found');
            }
          }}
          onDismiss={() => {
            setUserToRemove(null);
          }}
        />
      )}
    </>
  );
};
