import React from 'react';

import { HorizontalGroup } from '@grafana/ui';

import { DeleteApiKey } from './DeleteApiKey';
import { EditApiKey } from './EditApiKey';
import { ApiKey } from 'types';

interface Props {
  apiKeys: ApiKey[];
  refetch: () => void;
}

export const ApiKeysList: React.FC<Props> = ({ apiKeys, refetch }) => {
  return (
    <>
      <table className="filter-table form-inline" style={{ marginBottom: '4px' }}>
        <thead>
          <tr>
            <th>Prefix</th>
            <th>Name</th>
            <th>Expiration Date</th>
            <th>Expired</th>
            <th>Comment</th>
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {apiKeys.map((apiKey) => (
            <tr key={apiKey.name}>
              <td>{apiKey.id.substring(0, 8)}</td>
              <td>{apiKey.name}</td>
              <td>{new Date(apiKey.expirationDate).toLocaleDateString()}</td>
              <td>{new Date(apiKey.expirationDate) < new Date() ? 'Yes' : 'No'}</td>
              <td>{apiKey.comment}</td>
              <td>
                <HorizontalGroup>
                  <EditApiKey apiKey={apiKey} refetch={refetch} />
                  <DeleteApiKey apiKey={apiKey} refetch={refetch} />
                </HorizontalGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
