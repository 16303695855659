import React, { useState, useMemo } from 'react';
import { Badge, Button, ConfirmModal, HorizontalGroup, Pagination, RadioButtonGroup } from '@grafana/ui';
import { isDefined, User, Vessel, VesselGroup } from 'types';
import { VesselToolbar } from './VesselToolbar';
import { useMutation } from '@apollo/client';
import {
  AddVesselsToUserResult,
  AddVesselsToUserVariables,
  ADD_VESSELS_TO_USER,
  RemoveVesselsFromUserResult,
  RemoveVesselsFromUserVariables,
  REMOVE_VESSELS_FROM_USER,
} from 'graphql/mutations';
import { useAppNotification } from 'notifications';
import { VesselIdFieldsResult } from 'graphql/fragments';
import { usePaging } from 'ecomate-hooks';

interface Props {
  groupedVessels: Array<VesselGroup<{ id: number }>>;
  directVessels: Vessel[];
  user: User;
}

interface GroupedVessel extends VesselIdFieldsResult {
  group?: string;
  groups?: string[] | undefined;
  groupCount?: number | undefined;
  kind: string[];
}

function vesselSort<T extends GroupedVessel>(a: T, b: T): number {
  if (a.kind.includes('direct') && !b.kind.includes('direct')) {
    return -1;
  }
  if (b.kind.includes('direct') && !a.kind.includes('direct')) {
    return 1;
  }
  if (a.group && b.group && a.group !== b.group) {
    return a.group.localeCompare(b.group);
  }
  return a.name.localeCompare(b.name);
}

export const UsersVesselList: React.FC<Props> = ({ groupedVessels, directVessels, user }) => {
  const notifier = useAppNotification();

  const [addVessel] = useMutation<AddVesselsToUserResult, AddVesselsToUserVariables>(ADD_VESSELS_TO_USER, {
    onError() {
      notifier.error('Failed to give user access to vessel');
    },
    update(cache, { data }) {
      if (data) {
        let newVessels = data.addVesselsToUser.vessels.filter((e) => !user.vessels.find((v) => v.id === e.id));
        if (newVessels.length === 1) {
          notifier.success(`Added access to ${newVessels[0]?.name || 'vessel'} for ${data.addVesselsToUser.login}`);
        } else {
          notifier.success(`Added access to ${newVessels.length} vessels for ${data.addVesselsToUser.login}`);
        }
        cache.modify({
          id: cache.identify({ ...data.addVesselsToUser }),
          fields: {
            vessels() {
              return data.addVesselsToUser.vessels;
            },
          },
        });
      }
    },
  });

  const [removeVessel] = useMutation<RemoveVesselsFromUserResult, RemoveVesselsFromUserVariables>(
    REMOVE_VESSELS_FROM_USER,
    {
      onCompleted: (e) => {
        notifier.success(`Revoked access to vessel from user ${e.removeVesselsFromUser.login}`);
      },
      onError: () => {
        notifier.error(`Failed to revoke vessel access from user`);
      },
      update(cache, { data }) {
        if (data) {
          cache.modify({
            id: cache.identify({ ...data.removeVesselsFromUser }),
            fields: {
              vessels() {
                return data.removeVesselsFromUser.vessels;
              },
            },
          });
        }
      },
    }
  );

  const vessels = useMemo(() => {
    return [
      ...directVessels.map((e) => ({ ...e, group: '', kind: ['direct'] })),
      ...groupedVessels
        .filter((e) => e.vessels?.length)
        .flatMap((e) => e.vessels!.map((v) => ({ ...v, group: e.name, kind: ['group'] }))),
    ]
      .reduce<GroupedVessel[]>((prev, curr) => {
        const existing = prev.find((e) => e.id === curr.id);
        if (existing) {
          if (curr.group) {
            existing.groups = ([] as string[]).concat(existing.groups || [], curr.group);
          }
          if (existing.kind.length === 1 && existing.kind[0] !== curr.kind[0]) {
            existing.kind.push(curr.kind[0]);
          }
        } else {
          prev.push(curr);
        }
        return prev;
      }, [])
      .map((e) => {
        if (e.groups?.length) {
          if (e.groups.length > 1) {
            e.group = `${e.groups[0]} and ${e.groups.length - 1} others`;
          } else if (e.groups.length === 1) {
            e.group = e.groups[0];
          }
        }
        return e;
      });
  }, [directVessels, groupedVessels]);

  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [vesselToRemove, setVesselToRemove] = useState<VesselIdFieldsResult | null>(null);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: vessels || [],
    pageSize,
    searchQuery,
    options: {
      keys: ['name', 'group', 'imo'],
      sort: vesselSort,
    },
  });

  return (
    <>
      <VesselToolbar
        placeholder="Search for vessel by name, IMO or group"
        onAdd={(vessels) => {
          if (vessels.length && user.id) {
            addVessel({ variables: { vesselIds: vessels.map((e) => e.id).filter(isDefined), id: user.id } });
          }
        }}
        onSearch={setSearchQuery}
        initialSearchQuery={''}
        existingVessels={user.vessels}
        canAdd
      />
      <table className="filter-table form-inline" style={{ marginBottom: '4px' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th className="width-1">Access</th>
            <th>Group</th>
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {entities.map((vessel) => (
            <tr key={vessel.id}>
              <td>{vessel.name}</td>
              <td>
                <HorizontalGroup>
                  {vessel.kind.find((e) => e === 'direct') && (
                    <Badge
                      style={{ lineHeight: 1.5 }}
                      text={'Direct'}
                      icon="message"
                      tooltip="This vessel has been added outside a group"
                      color={'blue'}
                    />
                  )}
                  {vessel.kind.find((e) => e === 'group') && (
                    <Badge
                      style={{ lineHeight: 1.5 }}
                      text={'Group access'}
                      icon="message"
                      tooltip="This vessel has been added with a group"
                      color={'green'}
                    />
                  )}
                </HorizontalGroup>
              </td>
              <td>{vessel.group}</td>
              <td>
                {vessel.kind.find((e) => e === 'direct') && (
                  <Button
                    variant="destructive"
                    size="sm"
                    icon="times"
                    onClick={() => {
                      setVesselToRemove(vessel);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <HorizontalGroup justify="space-between" align="flex-start">
        {vessels.length > 10 && (
          <HorizontalGroup>
            <span>Page size:</span>
            <RadioButtonGroup
              options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
              onChange={setPageSize}
              value={pageSize}
              size="sm"
            />
          </HorizontalGroup>
        )}
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>
      {Boolean(vesselToRemove) && (
        <ConfirmModal
          isOpen={Boolean(vesselToRemove)}
          title="Revoke access"
          body={`Are you sure you want to revoke access to vessel ${vesselToRemove?.name}?`}
          confirmText="Revoke"
          onConfirm={() => {
            if (vesselToRemove?.id && user.id) {
              removeVessel({ variables: { id: user.id, vesselIds: [vesselToRemove!.id] } });
              setVesselToRemove(null);
            } else {
              notifier.warning('Vessel and/or user not found');
            }
          }}
          onDismiss={() => {
            setVesselToRemove(null);
          }}
        />
      )}
    </>
  );
};
