import React, { useState } from 'react';
import { Button, Field, Form, HorizontalGroup, InputControl, Modal, MultiSelect } from '@grafana/ui';
import { isDefined, BaseUser } from 'types';
import { SelectableValue } from '@grafana/data';
import { useQuery } from '@apollo/client';
import { GetAllUsersResult, GET_ALL_USERS } from 'graphql/queries';
import { Toolbar, ToolbarProps } from './Toolbar';
import { userSort } from 'pages/UsersPage';

interface Props extends Omit<ToolbarProps, 'onAdd'> {
  onAdd: (users: Array<Partial<BaseUser>>) => void;
  existingUsers?: BaseUser[];
}

export const GroupUsersToolbar: React.FC<Props> = (props) => {
  const { onAdd, existingUsers } = props;
  const allUsers = useQuery<GetAllUsersResult>(GET_ALL_USERS);

  const [userToAdd, setUserToAdd] = useState<Partial<BaseUser> | null>(null);

  return (
    <>
      <Toolbar {...props} onAdd={() => setUserToAdd({})} addText="Add user" />
      {Boolean(userToAdd) && (
        <Modal title="Add user" isOpen onDismiss={() => setUserToAdd(null)}>
          <Form
            onSubmit={({ users }: { users: Array<SelectableValue<Partial<BaseUser>>> }) => {
              if (users && users.length) {
                onAdd(users.map((e) => e.value).filter(isDefined));
              }
              setUserToAdd(null);
            }}
          >
            {({ register, control, errors }) => (
              <>
                <Field label="User">
                  <InputControl
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        isLoading={allUsers.loading}
                        placeholder="Select user"
                        options={allUsers.data?.users
                          .filter((e) => e.login?.length)
                          .filter((user) => !existingUsers?.find((e) => e.id === user.id))
                          .sort(userSort(-1))
                          .map(
                            (e) =>
                              ({ label: e.login, value: e, imgUrl: e.avatarUrl } as SelectableValue<Partial<BaseUser>>)
                          )}
                      />
                    )}
                    name="users"
                    control={control}
                  />
                </Field>
                <HorizontalGroup align="flex-end" spacing="md">
                  <Button type="submit">Add</Button>
                </HorizontalGroup>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
