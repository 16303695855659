import React, { useState } from 'react';
import { Button, ConfirmModal, HorizontalGroup, Pagination, RadioButtonGroup } from '@grafana/ui';
import { isDefined, Vessel, VesselGroup } from 'types';
import { VesselToolbar } from './VesselToolbar';
import { useMutation } from '@apollo/client';
import {
  AddVesselsToVesselGroupResult,
  AddVesselsToVesselGroupVariables,
  ADD_VESSELS_TO_VESSEL_GROUP,
  RemoveVesselsFromVesselGroupResult,
  RemoveVesselsFromVesselGroupVariables,
  REMOVE_VESSELS_FROM_VESSEL_GROUP,
} from 'graphql/mutations';
import { useAppNotification } from 'notifications';
import { VesselIdFieldsResult } from 'graphql/fragments';
import { usePaging } from 'ecomate-hooks';

interface Props {
  group: VesselGroup<{ id: number }>;
}

function vesselSort<T extends VesselIdFieldsResult>(a: T, b: T): number {
  return a.name.localeCompare(b.name);
}

export const VesselList: React.FC<Props> = ({ group }) => {
  const notifier = useAppNotification();

  const [addVessel] = useMutation<AddVesselsToVesselGroupResult, AddVesselsToVesselGroupVariables>(
    ADD_VESSELS_TO_VESSEL_GROUP,
    {
      onError() {
        notifier.error('Failed to add vessel to vessel group');
      },
      update(cache, { data }) {
        if (data) {
          let newVessels = data.addVesselsToGroup.vessels?.filter((e) => !group.vessels?.find((v) => v.id === e.id));
          if (newVessels?.length === 1) {
            notifier.success(`Added ${newVessels[0]?.name || 'vessel'} to ${data.addVesselsToGroup.name}`);
          } else {
            notifier.success(`Added ${newVessels?.length + ' ' ?? ''}vessels for ${data.addVesselsToGroup.name}`);
          }
          cache.modify({
            id: cache.identify({ ...data.addVesselsToGroup }),
            fields: {
              vessels() {
                return data.addVesselsToGroup.vessels;
              },
            },
          });
        }
      },
    }
  );

  const [removeVessel] = useMutation<RemoveVesselsFromVesselGroupResult, RemoveVesselsFromVesselGroupVariables>(
    REMOVE_VESSELS_FROM_VESSEL_GROUP,
    {
      onCompleted: (e) => {
        notifier.success(`Removed vessel from vessel group ${e.removeVesselsFromGroup.name}`);
      },
      onError: () => {
        notifier.error(`Failed to remove vessel from group`);
      },
      update(cache, { data }) {
        if (data) {
          cache.modify({
            id: cache.identify({ ...data.removeVesselsFromGroup }),
            fields: {
              vessels() {
                return data.removeVesselsFromGroup.vessels;
              },
            },
          });
        }
      },
    }
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [vesselToRemove, setVesselToRemove] = useState<Partial<Vessel> | null>(null);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: group.vessels || [],
    pageSize,
    searchQuery,
    options: {
      keys: ['name', 'imo', 'group'],
      sort: vesselSort,
    },
  });

  return (
    <>
      <VesselToolbar
        placeholder="Search for vessel by name, IMO or group"
        onAdd={(vessels) => {
          if (vessels.length && group.id) {
            addVessel({ variables: { vesselIds: vessels.map((e) => e.id).filter(isDefined), groupId: group.id } });
          }
        }}
        onSearch={setSearchQuery}
        initialSearchQuery={''}
        existingVessels={group.vessels}
        canAdd
      />
      <table className="filter-table form-inline" style={{ marginBottom: '4px' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>IMO</th>
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {entities.map((vessel) => (
            <tr key={vessel.id}>
              <td>{vessel.name}</td>
              <td>{vessel.imo}</td>
              <td>
                <Button
                  variant="destructive"
                  size="sm"
                  icon="times"
                  onClick={() => {
                    setVesselToRemove(vessel);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <HorizontalGroup justify="space-between" align="flex-start">
        {group.vessels && group.vessels.length > 10 && (
          <HorizontalGroup>
            <span>Page size:</span>
            <RadioButtonGroup
              options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
              onChange={setPageSize}
              value={pageSize}
              size="sm"
            />
          </HorizontalGroup>
        )}
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>
      {Boolean(vesselToRemove) && (
        <ConfirmModal
          isOpen={Boolean(vesselToRemove)}
          title="Remove vessel from group"
          body={`Are you sure you want to remove ${vesselToRemove?.name} from ${group.name || 'group'}?`}
          confirmText="Remove"
          onConfirm={() => {
            if (vesselToRemove?.id && group.id) {
              removeVessel({ variables: { groupId: group.id, vesselIds: [vesselToRemove!.id] } });
              setVesselToRemove(null);
            } else {
              notifier.warning('Vessel and/or group not found');
            }
          }}
          onDismiss={() => {
            setVesselToRemove(null);
          }}
        />
      )}
    </>
  );
};
