import { AppPlugin } from '@grafana/data';
import { CompanyManagementAppSettings } from 'types';
import App from './App';
import PluginConfig from './pages/PluginConfig';

const getPlugin = () => {
  let basePlugin = new AppPlugin<CompanyManagementAppSettings>().setRootPage(App).addConfigPage({
    title: 'Settings',
    icon: 'cog',
    body: PluginConfig,
    id: 'settings',
  });
  return basePlugin;
};

export const plugin = getPlugin();
