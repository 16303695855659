import React, { useState } from 'react';

import {
  Button,
  Form,
  Field,
  Input,
  DatePickerWithInput,
  Spinner,
  TextArea,
  FieldValidationMessage,
} from '@grafana/ui';

import { AddApiKeyResult, ApiKeyVariables } from 'graphql/mutations';

interface Props {
  loading: boolean;
  apiKey?: ApiKeyVariables;
  onSubmit: (variables: ApiKeyVariables) => void;
  apiKeyResult?: AddApiKeyResult | null;
}

export const ApiKeyForm: React.FC<Props> = ({ loading, onSubmit: addOrUpdate, apiKey, apiKeyResult }) => {
  // The maximum date that can be set, if beyond this date, validation fails.
  const maxDate = React.useMemo(() => new Date(new Date().setFullYear(new Date().getFullYear() + 1)), []);

  // Default suggested expiration date
  const defaultExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 1));

  const [expirationDate, setExpirationDate] = useState<Date>(apiKey?.expirationDate ?? defaultExpirationDate);
  const [errorMessage, setErrorMessage] = useState<string>();

  const performValidation = React.useCallback(() => {
    if (expirationDate > maxDate) {
      setErrorMessage('The maximum lifetime for a key is one year. Try setting an earlier expiration date');
      return false;
    } else if (expirationDate < new Date()) {
      setErrorMessage('The expiration date must be set in the future');
      return false;
    } else {
      setErrorMessage(undefined);
      return true;
    }
  }, [expirationDate, maxDate]);

  const onSubmit = React.useCallback(
    (variables: ApiKeyVariables) => {
      variables = { ...variables, expirationDate: expirationDate };
      if (performValidation() === true) {
        addOrUpdate(variables);
      }
    },
    [expirationDate, addOrUpdate, performValidation]
  );

  return (
    <Form<ApiKeyVariables> onSubmit={onSubmit} defaultValues={apiKey}>
      {({ register, errors }) => (
        <>
          <Field label="Name">
            <>
              <Input {...register('name', { required: true, minLength: 4 })} />
              {errors.name && (
                // @ts-ignore
                <FieldValidationMessage>Minimum amount of characters is four</FieldValidationMessage>
              )}
            </>
          </Field>
          <Field label="Expiration Date" invalid={errorMessage !== undefined} error={errorMessage}>
            <DatePickerWithInput value={expirationDate} onChange={(value) => setExpirationDate(new Date(value))} />
          </Field>
          <Field label="Comment">
            <TextArea style={{ height: 178 }} {...register('comment', { required: false })} />
          </Field>
          <Button disabled={loading} variant="primary" type="submit">
            {loading ? <Spinner /> : apiKey === undefined ? 'Add' : 'Edit'}
          </Button>
        </>
      )}
    </Form>
  );
};
