import { IconName } from '@grafana/ui';
import {
  BaseUserFragmentResult,
  UserFragmentResult,
  VesselIdFieldsResult,
  VesselMetaFieldsResult,
} from 'graphql/fragments';

// not undefined type predicate
export function isDefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export interface CompanyOptions {
  enableApiKeys?: boolean;
}

export interface CompanyManagementAppSettings {
  datasource?: string;
  options?: CompanyOptions;
}

export interface NavigationItem {
  label: string;
  icon?: IconName;
  active: boolean;
  url: string;
  access: UserRole;
  page: JSX.Element;
  internal?: boolean;
  requireOption?: keyof CompanyOptions;
}

export interface NavigationItemWithAccess extends NavigationItem {
  hasAccess: boolean;
}

export type PageType = 'settings' | 'groups' | 'users';

export interface VesselMetadata {
  imo?: string;
  mMSI?: string;
  vesselType?: string;
  operatingStatus?: string;
  flag?: string;
  grossTonnage?: number;
  deadweight?: number;
  length?: number;
  breadth?: number;
  yearBuilt?: number;
}

export interface Vessel extends VesselMetaFieldsResult, VesselIdFieldsResult {}

export interface BaseUser extends BaseUserFragmentResult {}

export interface User extends BaseUser, UserFragmentResult {}

export type UserRole = 'RESTRICTED_VIEWER' | 'VIEWER' | 'ADMINISTRATOR';

export interface VesselGroupVessel {
  vessel: Vessel;
  vesselGroup: VesselGroup;
  vesselGroupId: number;
  vesselId: string;
}

export interface VesselGroup<U = BaseUser> extends VesselGroupWithVessels {
  users?: U[];
}

export interface VesselGroupWithVessels extends BaseVesselGroup {
  vessels?: VesselIdFieldsResult[];
}

export interface BaseVesselGroup {
  description?: string;
  id?: number;
  name?: string;
}

export interface Scu {
  id: string;
  vesselId: string;
  firstSeen: string;
  lastSeen: string;
  status: 'ASSIGNED' | 'PENDING' | 'UNASSIGNED';
}

export interface ApiKey {
  id: string;
  name: string;
  expirationDate: Date;
  comment?: string;
  createdDate: Date;
  modifiedDate: Date;
  deleted: boolean;
}

type KeyOf<ObjectType extends {}> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends {} ? `${Key}` : `${Key}`;
}[keyof ObjectType & (string | number)];

type NestedKeyOfInternal<ObjectType extends {}> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends {}
    ? `${Key}` | `${Key}.${KeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

// The below is needed to avoid TS2345 error (https://github.com/microsoft/TypeScript/issues/26627)
export type NestedKeyOf<T> = T extends {} ? NestedKeyOfInternal<T> : never;
