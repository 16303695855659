import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { GetAllUsersResult, GET_ALL_USERS } from 'graphql/queries';
import { UsersTable } from 'components/UsersTable';
import {
  AddUserResult,
  AddUserVariables,
  ADD_USER,
  RemoveUserResult,
  RemoveUserVariables,
  REMOVE_USER,
  UpdateUserResult,
  UpdateUserVariables,
  UPDATE_USER,
} from 'graphql/mutations';
import { BaseUser, User } from 'types';
import { useAppNotification } from 'notifications';
import { Drawer, HorizontalGroup, LoadingPlaceholder, Pagination, RadioButtonGroup, Tab, TabsBar } from '@grafana/ui';
import { UsersVesselList } from 'components/UsersVesselList';
import { UsersGroupList } from 'components/UsersGroupList';
import { UsersToolbar } from 'components/UsersToolbar';
import { getContextSrv, usePaging } from 'ecomate-hooks';

export const userSort = (currentUserId: number) => (a: BaseUser, b: BaseUser) => {
  if (a.grafanaUserId === currentUserId) {
    return -1;
  }
  if (b.grafanaUserId === currentUserId) {
    return 1;
  }
  return a.login?.localeCompare(b.login || '') || 0;
};

export const UsersPage = () => {
  const notifier = useAppNotification();
  const currentUser = getContextSrv()?.user;

  const [showDetails, setShowDetails] = useState<User | null>(null);
  const [tab, setTab] = useState<'vessel' | 'groups'>('vessel');

  const users = useQuery<GetAllUsersResult>(GET_ALL_USERS, {
    onError(error) {
      notifier.error(`Failed to load users: ${error.message}`);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setShowDetails((prev) => {
      if (prev) {
        return users.data?.users.find((u) => u.id === prev.id) || null;
      }
      return prev;
    });
  }, [users]);

  const [addUser] = useMutation<AddUserResult, AddUserVariables>(ADD_USER, {
    onCompleted: (e) => {
      notifier.success(`User ${e.addUser.login} added`);
    },
    onError: () => {
      notifier.error('Failed to add user');
    },
    update(cache, { data }) {
      if (data) {
        const { users } = cache.readQuery<GetAllUsersResult>({ query: GET_ALL_USERS }) || { users: [] };
        cache.writeQuery({
          query: GET_ALL_USERS,
          data: { users: [...users, data.addUser].sort(userSort(currentUser?.id || -1)) },
        });
      }
    },
  });

  const [updateUser] = useMutation<UpdateUserResult, UpdateUserVariables>(UPDATE_USER, {
    onCompleted: (e) => {
      notifier.success(`User ${e.updateUser.login} updated`);
    },
    onError: () => {
      notifier.error(`Failed to update user`);
    },
    update(cache, { data }) {
      if (data) {
        cache.modify({
          id: cache.identify({ ...data.updateUser }),
          fields: {
            role() {
              return data.updateUser.role;
            },
          },
        });
      }
    },
  });

  // mutation to remove user and remove returned user from cache
  const [removeUser] = useMutation<RemoveUserResult, RemoveUserVariables>(REMOVE_USER, {
    onCompleted: (e) => {
      notifier.success(`User ${e.removeUser?.login} deleted`);
    },
    onError: () => {
      notifier.error(`Failed to delete user`);
    },
    update(cache, { data }) {
      if (data) {
        cache.evict({ id: cache.identify({ ...data.removeUser }) });
      }
    },
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: users.data?.users,
    pageSize,
    searchQuery,
    options: {
      keys: ['login', 'email'],
      sort: userSort(currentUser?.id || -1),
    },
  });

  return (
    <div>
      <UsersToolbar
        placeholder="Search users by login, email or name"
        onAdd={({ sendEmail, email, role }) => {
          if (email && role) {
            addUser({ variables: { sendEmail: Boolean(sendEmail), email, role } });
          } else {
            notifier.error('Email and role are required');
          }
        }}
        onSearch={setSearchQuery}
        onRefresh={() => users.refetch()}
        loading={users.loading || users.networkStatus === NetworkStatus.refetch}
        initialSearchQuery={searchQuery}
        canAdd
      />
      {Boolean(users.data) || <LoadingPlaceholder text="Loading..." />}
      {Boolean(users.data) && (
        <UsersTable
          users={entities}
          onRoleChange={(user, role) => updateUser({ variables: { id: user.id, role } })}
          onRemoveUser={(user) => [removeUser({ variables: { id: user.id } })]}
          onShowGroups={(user) => {
            setShowDetails(user);
            setTab('groups');
          }}
          onShowVessels={(user) => {
            setShowDetails(user);
            setTab('vessel');
          }}
        />
      )}
      <HorizontalGroup justify="space-between" align="flex-start">
        <HorizontalGroup>
          <span>Page size:</span>
          <RadioButtonGroup
            options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
            onChange={setPageSize}
            value={pageSize}
            size="sm"
          />
        </HorizontalGroup>
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>

      {showDetails && (
        <Drawer
          title={`Details for user ${showDetails.login}`}
          onClose={() => setShowDetails(null)}
          width={'700px'}
          tabs={
            <TabsBar>
              <Tab label="Vessels" active={tab === 'vessel'} onChangeTab={() => setTab('vessel')} />
              <Tab label="Groups" active={tab === 'groups'} onChangeTab={() => setTab('groups')} />
            </TabsBar>
          }
        >
          {tab === 'vessel' && (
            <UsersVesselList
              user={showDetails}
              groupedVessels={showDetails.vesselGroups || []}
              directVessels={showDetails.vessels}
            />
          )}
          {tab === 'groups' && <UsersGroupList user={showDetails} groups={showDetails.vesselGroups || []} />}
        </Drawer>
      )}
    </div>
  );
};
