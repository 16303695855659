import { gql } from '@apollo/client';
import { ApiKey, BaseVesselGroup, UserRole, VesselGroup, VesselMetadata } from 'types';
import {
  ApiKeyFragment,
  BaseVesselGroupFields,
  Preferences,
  PreferencesFragment,
  UnitPreference,
  UserAndOtherPreferences,
  UserFragment,
  UserFragmentResult,
  VesselGroupFields,
  VesselGroupWithVesselsFields,
  VesselGroupWithVesselsFieldsResult,
} from './fragments';

// #region User section
// GrapQL mutation to add a new user with email, role and sendEmail
export const ADD_USER = gql`
  mutation AddUser($email: String!, $role: UserRole!, $sendEmail: Boolean!) {
    addUser(input: { email: $email, role: $role, sendEmail: $sendEmail }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// GraphQL mutation to update a user with role and userId
export const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $role: UserRole!) {
    updateUser(input: { userId: $id, role: $role }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// GraphQL mutation to remove a user with userId
export const REMOVE_USER = gql`
  mutation RemoveUser($id: Int!) {
    removeUser(input: { userId: $id }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// #endregion

// #region Vessel section
// GraphQL mutation to update a vessel with vesselId, vesselName and metadata of type VesselMetadataInput
export const UPDATE_VESSEL = gql`
  mutation UpdateVessel($vesselId: String!, $vesselName: String!, $metadata: VesselMetadataInput!) {
    updateVessel(input: { vesselId: $vesselId, vesselName: $vesselName, metadata: $metadata })
  }
`;

// #endregion

// #region VesselGroup section
// GraphQL mutation to add a vesselGroup with description and name
export const ADD_VESSEL_GROUP = gql`
  ${BaseVesselGroupFields}
  mutation AddVesselGroup($description: String, $name: String!) {
    addVesselGroup(input: { description: $description, name: $name }) {
      ...BaseVesselGroupFields
    }
  }
`;

// GraphQL mutation to remove a vesselGroup with vesselGroupId
export const REMOVE_VESSEL_GROUP = gql`
  ${BaseVesselGroupFields}
  mutation RemoveVesselGroup($id: Int!) {
    removeVesselGroup(input: { id: $id }) {
      ...BaseVesselGroupFields
    }
  }
`;

// GraphQL mutation to add vessels to a vesselGroup with groupId and vesselIds
export const ADD_VESSELS_TO_VESSEL_GROUP = gql`
  mutation AddVesselsToVesselGroup($groupId: Int!, $vesselIds: [String!]!) {
    addVesselsToGroup(input: { groupId: $groupId, vesselIds: $vesselIds }) {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// GraphQL mutation to remove vessels from a vesselGroup with groupId and vesselIds
export const REMOVE_VESSELS_FROM_VESSEL_GROUP = gql`
  mutation RemoveVesselsFromVesselGroup($groupId: Int!, $vesselIds: [String!]!) {
    removeVesselsFromGroup(input: { groupId: $groupId, vesselIds: $vesselIds }) {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// GraphQL mutation to add users to a vesselGroup with groupId and userIds
export const ADD_USERS_TO_VESSEL_GROUP = gql`
  mutation AddUsersToVesselGroup($groupId: Int!, $ids: [Int!]!) {
    addUsersToGroup(input: { groupId: $groupId, userIds: $ids }) {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// GraphQL mutation to remove users from a group with groupId and userIds
export const REMOVE_USERS_FROM_VESSEL_GROUP = gql`
  mutation RemoveUsersFromGroup($groupId: Int!, $ids: [Int!]!) {
    removeUsersFromGroup(input: { groupId: $groupId, userIds: $ids }) {
      ...VesselGroupWithVesselsFields
    }
  }
  ${VesselGroupWithVesselsFields}
`;

export interface RemoveUsersFromVesselGroupResult {
  removeUsersFromGroup: VesselGroupWithVesselsFieldsResult;
}

export interface RemoveUsersFromVesselGroupVariables {
  groupId: number;
  ids: number[];
}

// #endregion

// #region Direct user and vessel connection

// GraphQL mutation to add vessels to a user with userId and vesselIds
export const ADD_VESSELS_TO_USER = gql`
  mutation AddVesselsToUser($id: Int!, $vesselIds: [String!]!) {
    addVesselsToUser(input: { userId: $id, vesselIds: $vesselIds }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// GraphQL mutation to remove vessels from a user with userId and vesselIds
export const REMOVE_VESSELS_FROM_USER = gql`
  mutation RemoveVesselsFromUser($id: Int!, $vesselIds: [String!]!) {
    removeVesselsFromUser(input: { userId: $id, vesselIds: $vesselIds }) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// #endregion

// Interfaces for the result and variables of the above mutation
export interface AddUserResult {
  addUser: UserFragmentResult;
}

export interface AddUserVariables {
  email: string;
  sendEmail: boolean;
  role: UserRole;
}

export interface UpdateUserResult {
  updateUser: UserFragmentResult;
}

export interface UpdateUserVariables {
  id: number;
  role: UserRole;
}

export interface RemoveUserResult {
  removeUser: UserFragmentResult;
}

export interface RemoveUserVariables {
  id: number;
}

export interface UpdateVesselResult {
  updateVessel: boolean;
}

export interface UpdateVesselVariables {
  vesselId: string;
  vesselName: string;
  metadata: VesselMetadata;
}

export interface AddVesselGroupResult {
  addVesselGroup: BaseVesselGroup;
}

export interface AddVesselGroupVariables {
  description?: string;
  name: string;
}

export interface RemoveVesselGroupResult {
  removeVesselGroup: BaseVesselGroup;
}

export interface RemoveVesselGroupVariables {
  id: number;
}

export interface AddVesselsToVesselGroupResult {
  addVesselsToGroup: VesselGroup;
}

export interface AddVesselsToVesselGroupVariables {
  groupId: number;
  vesselIds: string[];
}

export interface RemoveVesselsFromVesselGroupResult {
  removeVesselsFromGroup: VesselGroup;
}

export interface RemoveVesselsFromVesselGroupVariables {
  groupId: number;
  vesselIds: string[];
}

export interface AddUsersToVesselGroupResult {
  addUsersToGroup: VesselGroup;
}

export interface AddUsersToVesselGroupVariables {
  groupId: number;
  ids: number[];
}

export interface AddVesselsToUserResult {
  addVesselsToUser: UserFragmentResult;
}

export interface AddVesselsToUserVariables {
  id: number;
  vesselIds: string[];
}

export interface RemoveVesselsFromUserResult {
  removeVesselsFromUser: UserFragmentResult;
}

export interface RemoveVesselsFromUserVariables {
  id: number;
  vesselIds: string[];
}

export interface ApiKeyVariables {
  name: string;
  expirationDate: Date;
  comment?: string;
}

export interface AddApiKeyVariables extends ApiKeyVariables {}

export interface UpdateApiKeyVariables extends ApiKeyVariables {
  id: string;
}

export interface DeleteApiKeyVariables {
  id: string;
}

export const ADD_APIKEY = gql`
  mutation AddApiKey($name: String!, $expirationDate: DateTime!, $comment: String!) {
    addApiKey(input: { name: $name, expirationDate: $expirationDate, comment: $comment }) {
      apiKey {
        ...ApiKeyFragment
      }
      key
    }
  }
  ${ApiKeyFragment}
`;

export const UPDATE_APIKEY = gql`
  mutation UpdateApiKey($id: String!, $name: String!, $expirationDate: DateTime!, $comment: String!) {
    updateApiKey(input: { id: $id, name: $name, expirationDate: $expirationDate, comment: $comment }) {
      ...ApiKeyFragment
    }
  }
  ${ApiKeyFragment}
`;

export const DELETE_APIKEY = gql`
  mutation DeleteApiKey($id: String!) {
    deleteApiKey(input: { id: $id }) {
      ...ApiKeyFragment
    }
  }
  ${ApiKeyFragment}
`;

export interface AddApiKeyResult {
  addApiKey: AddApiKey;
}

export interface AddApiKey {
  key: string;
  apiKey: ApiKey;
}

export interface ApiKeyResult {
  apiKeys: ApiKey[];
}

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($preferences: PreferencesInput, $unitPreferences: [UnitPreferenceInput!]) {
    updatePreferences(input: { preferences: $preferences, unitPreferences: $unitPreferences }) {
      ...PreferencesFragment
    }
  }
  ${PreferencesFragment}
`;

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($preferences: PreferencesInput, $unitPreferences: [UnitPreferenceInput!]) {
    updateUserPreferences(input: { preferences: $preferences, unitPreferences: $unitPreferences }) {
      ...PreferencesFragment
    }
  }
  ${PreferencesFragment}
`;

export interface UpdatePreferencesVariables {
  preferences?: Preferences;
  unitPreferences?: UnitPreference[];
}

export interface UpdatePreferencesResult {
  updatePreferences: UserAndOtherPreferences;
}

export interface UpdateUserPreferencesResult {
  updateUserPreferences: UserAndOtherPreferences;
}
