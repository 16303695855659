import React, { useState, FC, useCallback } from 'react';

import { Button, Modal } from '@grafana/ui';
import { ApiKeyForm } from './ApiKeyForm';
import { UpdateApiKeyVariables, UPDATE_APIKEY } from 'graphql/mutations';
import { ApolloError, useMutation } from '@apollo/client';
import { useAppNotification } from 'notifications';
import { ApiKey } from 'types';

interface Props {
  apiKey: ApiKey;
  refetch: () => void;
}

export const EditApiKey: FC<Props> = ({ apiKey, refetch }) => {
  const notifier = useAppNotification();
  const [isOpen, setIsOpen] = useState(false);

  const [updateApiKey, { loading: loading }] = useMutation<ApiKey, UpdateApiKeyVariables>(UPDATE_APIKEY, {
    onError(error: ApolloError) {
      notifier.error(`Failed to update API Key with name ${apiKey.name}`);
    },
  });

  const onUpdate = useCallback(
    (variables: UpdateApiKeyVariables) => {
      const asyncFunc = async (variables: UpdateApiKeyVariables) => {
        try {
          if (!loading) {
            const updated = await updateApiKey({ variables });
            if (updated.data) {
              setIsOpen(false);
              refetch();
              notifier.success(`API key with name ${variables.name} updated`);
            }
          }
        } catch (e) {
          notifier.error('Failed to apply changes on API Key');
        }
      };
      asyncFunc(variables);
    },
    [loading, notifier, refetch, updateApiKey]
  );

  return (
    <>
      <Button icon="edit" variant="primary" size="sm" onClick={() => setIsOpen(true)} />
      <Modal isOpen={isOpen} title="Edit API key" onDismiss={() => setIsOpen(false)}>
        <ApiKeyForm
          loading={false}
          onSubmit={(variables) => onUpdate({ ...variables, id: apiKey.id })}
          apiKey={{ name: apiKey.name, expirationDate: apiKey.expirationDate, comment: apiKey.comment }}
        />
      </Modal>
    </>
  );
};
