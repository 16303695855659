import React, { useState, useEffect } from 'react';
import { useAppNotification } from 'notifications';
import { VesselGroup } from 'types';
import { useMutation, useQuery } from '@apollo/client';
import { GetAllVesselGroupsResult, GET_ALL_VESSELGROUPS } from 'graphql/queries';
import { GroupToolbar } from 'components/GroupToolbar';
import {
  Button,
  ConfirmModal,
  Drawer,
  HorizontalGroup,
  LoadingPlaceholder,
  Pagination,
  RadioButtonGroup,
  Tab,
  TabsBar,
} from '@grafana/ui';
import { RemoveVesselGroupResult, RemoveVesselGroupVariables, REMOVE_VESSEL_GROUP } from 'graphql/mutations';
import { VesselList } from 'components/VesselList';
import { UserList } from 'components/UserList';
import { usePaging } from 'ecomate-hooks';

export const GroupsPage = () => {
  const notifier = useAppNotification();

  const [showDetails, setShowDetails] = useState<VesselGroup | null>(null);
  const [groupToRemove, setGroupToRemove] = useState<VesselGroup | null>(null);
  const [tab, setTab] = useState<'vessel' | 'user'>('vessel');

  const groups = useQuery<GetAllVesselGroupsResult>(GET_ALL_VESSELGROUPS);

  useEffect(() => {
    setShowDetails((prev) => {
      if (prev) {
        return groups.data?.vesselGroups.find((u) => u.id === prev.id) || null;
      }
      return prev;
    });
  }, [groups]);

  const [removeGroup] = useMutation<RemoveVesselGroupResult, RemoveVesselGroupVariables>(REMOVE_VESSEL_GROUP, {
    onError: () => {
      notifier.error(`Failed to remove group`);
    },
    update(cache, { data }) {
      if (data) {
        notifier.success(`Removed group ${data.removeVesselGroup.name}`);
        const { vesselGroups } = cache.readQuery<GetAllVesselGroupsResult>({ query: GET_ALL_VESSELGROUPS }) || {
          vesselGroups: [],
        };
        cache.writeQuery({
          query: GET_ALL_VESSELGROUPS,
          data: {
            vesselGroups: vesselGroups.filter((group) => group.id !== data.removeVesselGroup.id),
          },
        });
      }
    },
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: groups.data?.vesselGroups || [],
    pageSize,
    searchQuery,
    options: {
      keys: ['name'],
      sort: (a, b) => a.name?.localeCompare(b?.name),
    },
  });

  return (
    <div>
      <GroupToolbar
        placeholder="Search groups by name"
        onSearch={setSearchQuery}
        initialSearchQuery={''}
        canAdd
        loading={groups.loading || !entities}
        onRefresh={() => groups.refetch()}
      />
      {Boolean(entities) || <LoadingPlaceholder text="Loading..." />}
      {Boolean(entities) && (
        <>
          <table className="filter-table form-inline">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Vessels</th>
                <th style={{ width: '72px' }} />
                <th style={{ width: '34px' }} />
              </tr>
            </thead>
            <tbody>
              {entities.map((group) => (
                <tr key={group.id}>
                  <td className="width-5">
                    <span className="ellipsis" title={group.name}>
                      {group.name}
                    </span>
                  </td>
                  <td className="max-width-10">
                    <span className="ellipsis" title={group.description}>
                      {group.description}
                    </span>
                  </td>
                  <td className="max-width-8">
                    <span className="ellipsis" title={group.vessels.map((e) => e.name).join(', ')}>
                      {group.vessels.map((e) => e.name).join(', ')}
                    </span>{' '}
                  </td>
                  <td>
                    <HorizontalGroup>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setShowDetails(group);
                          setTab('vessel');
                        }}
                      >
                        Vessels
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setShowDetails(group);
                          setTab('user');
                        }}
                      >
                        Users
                      </Button>
                    </HorizontalGroup>
                  </td>
                  <td>
                    <Button
                      variant="destructive"
                      size="sm"
                      icon="times"
                      onClick={() => {
                        setGroupToRemove(group);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <HorizontalGroup justify="space-between" align="flex-start">
            {groups.data?.vesselGroups.length && groups.data.vesselGroups.length > 10 && (
              <HorizontalGroup>
                <span>Page size:</span>
                <RadioButtonGroup
                  options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
                  onChange={setPageSize}
                  value={pageSize}
                  size="sm"
                />
              </HorizontalGroup>
            )}
            <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
          </HorizontalGroup>
          {Boolean(groupToRemove) && (
            <ConfirmModal
              isOpen={Boolean(groupToRemove)}
              title="Delete group"
              body={`Are you sure you want to delete group ${groupToRemove?.name}?`}
              confirmText="Delete"
              onConfirm={() => {
                if (groupToRemove?.id) {
                  removeGroup({ variables: { id: groupToRemove.id } });
                } else {
                  notifier.error(`Failed to delete group`);
                }
                setGroupToRemove(null);
              }}
              onDismiss={() => {
                setGroupToRemove(null);
              }}
            />
          )}
        </>
      )}

      {showDetails && (
        <Drawer
          title={`Details for group ${showDetails.name}`}
          onClose={() => setShowDetails(null)}
          width={'700px'}
          tabs={
            <TabsBar>
              <Tab label="Vessels" active={tab === 'vessel'} onChangeTab={() => setTab('vessel')} />
              <Tab label="Users" active={tab === 'user'} onChangeTab={() => setTab('user')} />
            </TabsBar>
          }
        >
          {tab === 'vessel' && <VesselList group={showDetails} />}
          {tab === 'user' && <UserList group={showDetails} />}
        </Drawer>
      )}
    </div>
  );
};

export default GroupsPage;
