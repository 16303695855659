import React, { useState } from 'react';
import { Button, Field, Form, HorizontalGroup, Input, Modal } from '@grafana/ui';
import { VesselGroup } from 'types';
import { useMutation } from '@apollo/client';
import { GET_ALL_VESSELGROUPS, GetAllVesselGroupsResult } from 'graphql/queries';
import { Toolbar, ToolbarProps } from './Toolbar';
import { AddVesselGroupResult, AddVesselGroupVariables, ADD_VESSEL_GROUP } from 'graphql/mutations';
import { useAppNotification } from 'notifications';

interface GroupsToolbarProps extends Omit<ToolbarProps, 'onAdd'> {}

export const GroupToolbar: React.FC<GroupsToolbarProps> = (props) => {
  const notifier = useAppNotification();
  const [groupToAdd, setGroupToAdd] = useState<Partial<VesselGroup<{ id: number }>> | null>(null);

  const [createGroup] = useMutation<AddVesselGroupResult, AddVesselGroupVariables>(ADD_VESSEL_GROUP, {
    onCompleted: (e) => {
      if (e.addVesselGroup.name) {
        notifier.success(`Group ${e.addVesselGroup.name} added`);
      } else {
        notifier.success(`Group added`);
      }
    },
    onError: () => {
      notifier.error('Failed to create group');
    },
    update(cache, { data }) {
      if (data) {
        const { vesselGroups } = cache.readQuery<GetAllVesselGroupsResult>({ query: GET_ALL_VESSELGROUPS }) || {
          vesselGroups: [],
        };
        cache.writeQuery({
          query: GET_ALL_VESSELGROUPS,
          data: {
            vesselGroups: [...vesselGroups, data.addVesselGroup].sort(
              (a, b) => a.name?.localeCompare(b.name || '') || 0
            ),
          },
        });
      }
    },
  });

  return (
    <>
      <Toolbar {...props} onAdd={() => setGroupToAdd({})} addText="Add group" />
      {Boolean(groupToAdd) && (
        <Modal title="Add group" isOpen onDismiss={() => setGroupToAdd(null)}>
          <Form
            onSubmit={(group: AddVesselGroupVariables) => {
              createGroup({ variables: { ...group } });
              setGroupToAdd(null);
            }}
          >
            {({ register, control, errors }) => (
              <>
                <Field label="Name" invalid={!!errors.name} error="Name is required">
                  <Input placeholder="Name" {...register('name', { required: true })} />
                </Field>
                <Field label="Description">
                  <Input placeholder="Description" {...register('description')} />
                </Field>
                <HorizontalGroup align="flex-end" spacing="md">
                  <Button type="submit">Add</Button>
                </HorizontalGroup>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
