import React, { useState, useCallback } from 'react';

import { Toolbar, ToolbarProps } from '../Toolbar';
import { ApiKeyForm } from './ApiKeyForm';
import { ApiKeyVariables, AddApiKeyResult, AddApiKeyVariables, ADD_APIKEY } from 'graphql/mutations';
import { ApolloError, useMutation } from '@apollo/client';
import { useAppNotification } from 'notifications';
import { Modal } from '@grafana/ui';
import { AddApiKeyResponse } from './AddApiKeyResponse';

interface Props {
  refetch: () => void;
  toolbarProps: Omit<ToolbarProps, 'onAdd'>;
}

export const ApiKeysToolbar: React.FC<Props> = ({ refetch, toolbarProps }) => {
  const notifier = useAppNotification();

  const [responseIsOpen, setResponseIsOpen] = useState(false);
  const [addIsOpen, setAddIsOpen] = useState(false);

  const [addApiKey, { loading: loading, data: data }] = useMutation<AddApiKeyResult, AddApiKeyVariables>(ADD_APIKEY, {
    onError(error: ApolloError) {
      notifier.error('Failed to add API Key');
    },
  });

  const onAdd = useCallback(
    (variables: ApiKeyVariables) => {
      const asyncFunc = async (variables: ApiKeyVariables) => {
        try {
          if (!loading) {
            const added = await addApiKey({ variables });
            if (added.data) {
              setAddIsOpen(false);
              setResponseIsOpen(true);
              refetch();
            }
          }
        } catch (e) {
          notifier.error('Failed to add API Key');
        }
      };
      asyncFunc(variables);
    },
    [addApiKey, loading, notifier, refetch]
  );

  return (
    <>
      <Toolbar {...toolbarProps} addText="Add API Key" canAdd onAdd={() => setAddIsOpen(true)} />
      <Modal title="Add API Key" isOpen={addIsOpen} onDismiss={() => setAddIsOpen(false)}>
        <ApiKeyForm loading={loading} onSubmit={onAdd} apiKeyResult={data} />
      </Modal>
      {data !== undefined && data !== null && (
        <Modal isOpen={responseIsOpen} title="API Key added" onDismiss={() => setResponseIsOpen(false)}>
          <AddApiKeyResponse apiKey={data} />
        </Modal>
      )}
    </>
  );
};
