import React from 'react';

import { TimeZone } from '@grafana/data';
import { VerticalGroup } from '@grafana/ui';
import { UserPreferences } from 'components/UserPreferences';
import { UserPreferencesGrafana } from 'components/UserPreferencesGrafana';

export interface Props {
  resourceUri: string;
  isAdmin: boolean;
}

interface UserPreferencesDTO {
  timezone: TimeZone;
  weekStart: string;
  theme: string;
}

export type State = UserPreferencesDTO;

export const SettingsPage: React.FC<Props> = ({ resourceUri, isAdmin }) => {
  return (
    <VerticalGroup spacing="lg">
      <UserPreferencesGrafana resourceUri={'user'} />
      <UserPreferences isAdmin={isAdmin} />
    </VerticalGroup>
  );
};
