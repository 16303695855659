import React from 'react';
import { NavigationItem } from 'types';
import { Icon, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { useHistory } from 'react-router-dom';

export const SelectNav = ({ items, customCss }: { items: NavigationItem[]; customCss: string }) => {
  const history = useHistory();

  if (!items || items.length === 0) {
    return null;
  }

  const selectableItems = items.map<SelectableValue<NavigationItem>>((e) => ({ ...e, value: e }));

  const selectedItem = selectableItems.find((item) => item.active);

  return (
    <div className={`gf-form-select-wrapper width-20 ${customCss}`}>
      <Select
        prefix={selectedItem?.value?.icon && <Icon name={selectedItem.value.icon} />}
        value={selectedItem}
        options={selectableItems}
        onChange={(e) => e.value && history.push(e.value.url)}
      />
    </div>
  );
};
