import { gql } from '@apollo/client';
import { UserRole } from 'types';
import { Dimension } from './queries';

export const ApiKeyFragment = gql`
  fragment ApiKeyFragment on ApiKey {
    id
    name
    expirationDate
    comment
    createdDate
    modifiedDate
    deleted
  }
`;

export const BaseUserFragment = gql`
  fragment BaseUserFragment on User {
    __typename
    id
    email
    role
    lastSeenAt
    avatarUrl
    inviteUrl
    lastSeenAtAge
    name
    grafanaUserId
    login
    isInternal
  }
`;

export interface BaseUserFragmentResult {
  __typename: 'User';
  id: number;
  email: string;
  role: UserRole;
  lastSeenAt: string;
  avatarUrl: string;
  inviteUrl: string;
  lastSeenAtAge: string;
  name: string;
  grafanaUserId: number;
  login?: string;
  isInternal: boolean;
}

// A graphql fragment for type vessel with identity fields
export const VesselIdFields = gql`
  fragment VesselIdFields on Vessel {
    id
    name
    imo
  }
`;

// Type of result from vesselIdFields
export interface VesselIdFieldsResult {
  id: string;
  name: string;
  imo: string;
}

// A graphql fragment for type vessel with meta fields
export const VesselMetaFields = gql`
  fragment VesselMetaFields on Vessel {
    swVersion
    class
    ecomateType
    vesselType
    flag
    grossTonnage
    deadweight
    length
    breadth
    operatingStatus
    yearBuilt
  }
`;

// Type of result from vesselMetaFields
export interface VesselMetaFieldsResult {
  swVersion: string;
  class: string;
  ecomateType: string;
  vesselType: string;
  flag: string;
  grossTonnage: number;
  deadweight: number;
  length: number;
  breadth: number;
  operatingStatus: string;
  yearBuilt: number;
}

export const BaseVesselGroupFields = gql`
  fragment BaseVesselGroupFields on VesselGroup {
    id
    name
    description
  }
`;

export interface BaseVesselGroupFieldsResult {
  id: number;
  name: string;
  description: string;
}

export const VesselGroupWithVesselsFields = gql`
  fragment VesselGroupWithVesselsFields on VesselGroup {
    ...BaseVesselGroupFields
    vessels {
      ...VesselIdFields
      ...VesselMetaFields
    }
  }
  ${BaseVesselGroupFields}
  ${VesselIdFields}
  ${VesselMetaFields}
`;

export interface VesselGroupWithVesselsFieldsResult extends BaseVesselGroupFieldsResult {
  vessels: Array<VesselIdFieldsResult & VesselMetaFieldsResult>;
}

// A graphql fragment for type vessel with group fields
export const VesselGroupFields = gql`
  fragment VesselGroupFields on VesselGroup {
    ...VesselGroupWithVesselsFields
    users {
      ...BaseUserFragment
    }
  }
  ${VesselGroupWithVesselsFields}
  ${BaseUserFragment}
`;

// Type of result from vesselGroupFields
export interface VesselGroupFieldsResult extends VesselGroupWithVesselsFieldsResult {
  users: BaseUserFragmentResult[];
}

// Type of result from scuFields
export interface ScuFieldsResult {
  id: number;
  vesselId: number;
  firstSeen: string;
  lastSeen: string;
  status: string;
}

// GraphQL fragment for type user
export const UserFragment = gql`
  fragment UserFragment on User {
    ...BaseUserFragment
    vessels {
      ...VesselIdFields
      ...VesselMetaFields
    }
    vesselGroups {
      ...VesselGroupWithVesselsFields
    }
  }
  ${BaseUserFragment}
  ${VesselIdFields}
  ${VesselMetaFields}
  ${VesselGroupWithVesselsFields}
`;

// Type of result from userFragment
export interface UserFragmentResult extends BaseUserFragmentResult {
  vessels: Array<VesselIdFieldsResult & VesselMetaFieldsResult>;
  vesselGroups: VesselGroupWithVesselsFieldsResult[];
}

export const PreferencesFragment = gql`
  fragment PreferencesFragment on UnitAndOtherPreferences {
    preferences {
      volumeBasedConsumption
    }
    unitPreferences {
      dimension
      category
      unitId
    }
  }
`;

export type Preferences = {
  volumeBasedConsumption: boolean | null;
};

export type UnitPreference = {
  dimension: Dimension;
  category: string | null;
  unitId: number;
};

export type UserAndOtherPreferences = {
  preferences: Preferences;
  unitPreferences: UnitPreference[];
};
