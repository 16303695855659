import React from 'react';
import { NavigationItemWithAccess } from 'types';

interface Props {
  items?: NavigationItemWithAccess[];
}

export const Pages: React.FC<Props> = ({ items }) => {
  const item = items?.find((item) => item.active);

  if (!item) {
    return <>Page not found</>;
  }
  if (!item.hasAccess) {
    return <>Not authorized</>;
  }
  return item.page;
};
