import React from 'react';

import { Icon, Legend, LoadingPlaceholder, Select, Tooltip, VerticalGroup } from '@grafana/ui';
import { ApolloCache, DefaultContext, DocumentNode, MutationTuple, useMutation, useQuery } from '@apollo/client';
import {
  GET_UNITS,
  GetUnitsResult,
  GET_USER_PREFERENCES,
  GetUserPreferencesResult,
  Dimension,
  GetActualUnitsResult,
  GET_ACTUAL_UNITS,
  GetActualUnitsVariables,
} from 'graphql/queries';
import _ from 'lodash';
import {
  UPDATE_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  UpdatePreferencesResult,
  UpdatePreferencesVariables,
  UpdateUserPreferencesResult,
} from 'graphql/mutations';
import { useAppNotification } from 'notifications';
import { UnitPreference, UserAndOtherPreferences } from 'graphql/fragments';
import { SelectableValue } from '@grafana/data';

export interface Props {
  isAdmin: boolean;
}

type UnitSettings = {
  measurement: string;
  description?: string;
  dimension: Dimension;
  category: string | null;
  excludedUnitIds?: number[];
};

const unitList: UnitSettings[] = [
  {
    measurement: 'Massflow',
    description: 'Used for fuel consumption and flow rates',
    dimension: 'MASSFLOW',
    category: null,
  },
  {
    measurement: 'Mass',
    description: 'Used for fuel consumption, bunker amount and emissions',
    dimension: 'MASS',
    category: null,
  },
  {
    measurement: 'Volumeflow',
    description: 'Used for fuel consumption and flow rates',
    dimension: 'VOLUMEFLOW',
    category: null,
  },
  {
    measurement: 'Volume',
    description: 'Used for fuel consumption',
    dimension: 'VOLUME',
    category: null,
  },
  {
    measurement: 'Speed',
    dimension: 'SPEED',
    category: null,
  },
  {
    measurement: 'Distance',
    description: 'Used for vessel travelled distance',
    dimension: 'LENGTH',
    category: null,
    excludedUnitIds: [3, 109],
  },
  {
    measurement: 'Draft',
    description: 'Used for shorter distances such as: Draft, etc',
    dimension: 'LENGTH',
    category: 'DRAFT',
    excludedUnitIds: [107, 108],
  },
  {
    measurement: 'Density',
    dimension: 'DENSITY',
    category: null,
  },
  {
    measurement: 'Temperature',
    dimension: 'TEMPERATURE',
    category: null,
  },
  {
    measurement: 'Power',
    dimension: 'POWER',
    category: null,
  },
  {
    measurement: 'Torque',
    dimension: 'TORQUE',
    category: null,
  },
];

const consumptionOptions: SelectableValue[] = [
  { value: 'default', label: '---', description: 'Uses system default', volumeSetting: null },
  { value: 'mass', label: 'Mass', description: 'Prefer mass', volumeSetting: false },
  { value: 'volume', label: 'Volume', description: 'Prefer volume', volumeSetting: true },
];

const InfoIcon: React.FC<{ tooltip: string }> = ({ tooltip }) => (
  <Tooltip interactive={false} placement="top" content={tooltip} theme="info">
    <Icon tabIndex={0} name="info-circle" size="sm" />
  </Tooltip>
);

export const UserPreferences: React.FC<Props> = ({ isAdmin }) => {
  const notifier = useAppNotification();

  // Custom hoook to reuse logic between mutation for user and global unit preferences.
  const useUpdatePreferencesMutation = <TResult extends UpdatePreferencesResult | UpdateUserPreferencesResult>(
    mutation: DocumentNode,
    queryField: keyof GetUserPreferencesResult,
    getResult: (result: TResult) => UserAndOtherPreferences
  ): MutationTuple<TResult, UpdatePreferencesVariables, DefaultContext, ApolloCache<any>> => {
    const result = useMutation<TResult, UpdatePreferencesVariables>(mutation, {
      onError: () => {
        notifier.error(`Failed to update preferences`);
      },
      refetchQueries: [GET_ACTUAL_UNITS],
      update(cache, { data }) {
        if (data) {
          notifier.success(`Preferences updated`);
          const result = cache.readQuery<GetUserPreferencesResult>({ query: GET_USER_PREFERENCES });
          if (result) {
            cache.writeQuery({
              query: GET_USER_PREFERENCES,
              data: {
                ...result,
                [queryField]: getResult(data),
              },
            });
          }
        }
      },
    });
    return result;
  };

  const { data: { units } = { units: [] } } = useQuery<GetUnitsResult>(GET_UNITS);
  const { data: { actualUnits } = {} } = useQuery<GetActualUnitsResult, GetActualUnitsVariables>(GET_ACTUAL_UNITS, {
    // Avoid caching and ensure we always call the API to get the actual used units.
    fetchPolicy: 'no-cache',
    variables: {
      unitCategories: unitList.map((u) => ({ dimension: u.dimension, category: u.category })),
    },
  });
  const { data: { preferences, userPreferences } = {} } = useQuery<GetUserPreferencesResult>(GET_USER_PREFERENCES);
  const [updatePreferences] = useUpdatePreferencesMutation<UpdatePreferencesResult>(
    UPDATE_PREFERENCES,
    'preferences',
    (result) => result.updatePreferences
  );
  const [updateUserPreferences] = useUpdatePreferencesMutation<UpdateUserPreferencesResult>(
    UPDATE_USER_PREFERENCES,
    'userPreferences',
    (result) => result.updateUserPreferences
  );

  const unitSettings = unitList.map((unit) => {
    return {
      ...unit,
      companyUnitId:
        preferences?.unitPreferences?.find((u) => u.category === unit.category && u.dimension === unit.dimension)
          ?.unitId ?? -1,
      userUnitId:
        userPreferences?.unitPreferences?.find((u) => u.category === unit.category && u.dimension === unit.dimension)
          ?.unitId ?? -1,
    };
  });

  const getUnitsForDimension = (dimension: Dimension, excludedUnitIds: number[] | undefined) => {
    const filteredUnits = units
      .filter((u) => u.dimension === dimension && (!excludedUnitIds || !excludedUnitIds.includes(u.id)))
      .map((u) => {
        return {
          value: u.id,
          label: u.symbol,
          description: u.name,
        };
      });

    return filteredUnits && [{ label: '---', value: -1, description: 'Uses system default' }, ...filteredUnits];
  };

  if (!preferences || !userPreferences) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  const consumptionChanged = async (isUser: boolean, volumeSetting: boolean | null) => {
    const existingPreferences = isUser ? userPreferences : preferences;
    const payload = {
      variables: {
        preferences: existingPreferences && {
          volumeBasedConsumption: volumeSetting,
        },
      },
    };
    if (isUser) {
      await updateUserPreferences(payload);
    } else {
      await updatePreferences(payload);
    }
  };

  const unitChanged = async (isUser: boolean, settings: UnitSettings, unitId: number | undefined) => {
    const preference: UnitPreference = {
      dimension: settings.dimension,
      category: settings.category,
      unitId: unitId ?? -1,
    };
    const existingPreferences = isUser ? userPreferences : preferences;
    const updatedPreferences = [
      ...existingPreferences.unitPreferences.filter(
        (p) => p.dimension !== settings.dimension || p.category !== settings.category
      ),
      preference,
    ];
    const payload = {
      variables: {
        unitPreferences: updatedPreferences
          .map((u) => {
            return {
              dimension: u.dimension,
              category: u.category,
              unitId: u.unitId,
            };
          })
          .filter((u) => u.unitId > -1),
      },
    };
    if (isUser) {
      await updateUserPreferences(payload);
    } else {
      await updatePreferences(payload);
    }
  };

  const usesVolume =
    userPreferences.preferences.volumeBasedConsumption ?? preferences.preferences.volumeBasedConsumption;

  return (
    <VerticalGroup>
      <Legend>Unit Preferences</Legend>
      <table
        className="filter-table form-inline"
        style={{ marginBottom: '4px', tableLayout: 'fixed', maxWidth: '800px' }}
      >
        <thead>
          <tr>
            <th>Measurement</th>
            <th>
              Company Setting&nbsp;
              <InfoIcon tooltip="Settings for all users" />
            </th>
            <th>
              My Setting&nbsp;
              <InfoIcon tooltip="Settings for my user only" />
            </th>
            <th>Used Setting</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Consumption &nbsp;
              <InfoIcon
                tooltip="Choose if mass or volume is preferred when showing consumption.
              The conversion from mass to volume uses the density settings for the fuels used for each consumer. If density is not set/available a default of 850 kg/m³ is used.
              Note that mass is used internally and will still be shown in some cases when volume is selected."
              />
            </td>
            <td>
              <Select
                options={consumptionOptions}
                disabled={!isAdmin}
                value={
                  consumptionOptions.find(
                    (item) => item.volumeSetting === preferences.preferences.volumeBasedConsumption
                  )?.value
                }
                onChange={(e) => consumptionChanged(false, e.volumeSetting)}
              />
            </td>
            <td>
              <Select
                options={consumptionOptions}
                value={
                  consumptionOptions.find(
                    (item) => item.volumeSetting === userPreferences.preferences.volumeBasedConsumption
                  )?.value
                }
                onChange={(e) => consumptionChanged(true, e.volumeSetting)}
              />
            </td>
            <td>{usesVolume ? 'Volume' : 'Mass'}</td>
          </tr>
          {unitSettings.map((unit, index) => (
            <tr key={index}>
              <td>
                {unit.measurement}
                {unit.description && (
                  <>
                    &nbsp;
                    <InfoIcon tooltip={unit.description} />
                  </>
                )}
              </td>
              <td>
                <Select
                  options={getUnitsForDimension(unit.dimension, unit.excludedUnitIds)}
                  disabled={!isAdmin}
                  value={unit.companyUnitId}
                  onChange={(e) => unitChanged(false, unit, e.value)}
                />
              </td>
              <td>
                <Select
                  options={getUnitsForDimension(unit.dimension, unit.excludedUnitIds)}
                  value={unit.userUnitId}
                  onChange={(e) => unitChanged(true, unit, e.value)}
                />
              </td>
              <td>
                {actualUnits?.find((u) => u.category === unit.category && u.dimension === unit.dimension)?.symbol ??
                  '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </VerticalGroup>
  );
};
