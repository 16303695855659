import React, { useState } from 'react';
import { Button, Field, Form, HorizontalGroup, InputControl, Modal, MultiSelect } from '@grafana/ui';
import { isDefined, User, VesselGroup } from 'types';
import { SelectableValue } from '@grafana/data';
import { useQuery } from '@apollo/client';
import { GET_ALL_VESSELGROUPS, GetAllVesselGroupsResult } from 'graphql/queries';
import { Toolbar, ToolbarProps } from './Toolbar';

interface UsersGroupToolbarProps extends Omit<ToolbarProps, 'onAdd'> {
  onAdd: (groups: Array<Partial<VesselGroup>>) => void;
  user: User;
}

export const UsersGroupToolbar: React.FC<UsersGroupToolbarProps> = (props) => {
  const { onAdd, user } = props;

  const allGroups = useQuery<GetAllVesselGroupsResult>(GET_ALL_VESSELGROUPS);

  const [groupToAdd, setGroupToAdd] = useState<Partial<VesselGroup> | null>(null);

  return (
    <>
      <Toolbar {...props} onAdd={() => setGroupToAdd({})} addText="Add group" />
      {Boolean(groupToAdd) && (
        <Modal title="Add Group" isOpen onDismiss={() => setGroupToAdd(null)}>
          <Form
            onSubmit={({ groups }: { groups: Array<SelectableValue<Partial<VesselGroup>>> }) => {
              if (groups && groups.length) {
                onAdd(groups.map((e) => e.value).filter(isDefined));
              }
              setGroupToAdd(null);
            }}
          >
            {({ register, control, errors }) => (
              <>
                <Field label="Vessel group">
                  <InputControl
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        placeholder="Select vessel group"
                        options={allGroups.data?.vesselGroups
                          .filter((group) => !user.vesselGroups?.find((e) => e.id === group.id))
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(
                            (e) =>
                              ({ label: e.name, value: e, description: e.description } as SelectableValue<
                                Partial<VesselGroup>
                              >)
                          )}
                      />
                    )}
                    name="groups"
                    control={control}
                  />
                </Field>
                <HorizontalGroup align="flex-end" spacing="md">
                  <Button type="submit">Add</Button>
                </HorizontalGroup>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
