import React, { useCallback, useState, FC } from 'react';

import { Button, ConfirmModal } from '@grafana/ui';
import { ApolloError, useMutation } from '@apollo/client';
import { DeleteApiKeyVariables, DELETE_APIKEY } from 'graphql/mutations';
import { useAppNotification } from 'notifications';
import { ApiKey } from 'types';

interface Props {
  apiKey: ApiKey;
  refetch: () => void;
}

export const DeleteApiKey: FC<Props> = ({ apiKey, refetch }) => {
  const notifier = useAppNotification();
  const [isOpen, setIsOpen] = useState(false);

  const [deleteApiKey, { loading: loading }] = useMutation<ApiKey, DeleteApiKeyVariables>(DELETE_APIKEY, {
    onError(error: ApolloError) {
      notifier.error(`Failed to delete API Key with name ${apiKey.name}`);
    },
  });

  const onDelete = useCallback(
    (variables: DeleteApiKeyVariables) => {
      const asyncFunc = async (variables: DeleteApiKeyVariables) => {
        try {
          if (!loading) {
            await deleteApiKey({ variables });
            refetch();
            setIsOpen(false);
            notifier.success(`API key with name ${apiKey.name} deleted`);
          }
        } catch (e) {
          notifier.error('Failed to delete API Key');
        }
      };
      asyncFunc(variables);
    },
    [apiKey.name, deleteApiKey, loading, notifier, refetch]
  );

  return (
    <>
      <Button variant="destructive" size="sm" icon="times" onClick={() => setIsOpen(true)} />
      <ConfirmModal
        onConfirm={() => onDelete({ id: apiKey.id })}
        confirmText="Delete"
        dismissText="Cancel"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        body={`Are you sure you want to delete this API key with name ${apiKey.name}?`}
        title="Delete API key"
      />
    </>
  );
};
