import React, { useState } from 'react';
import { FilterInput, Button } from '@grafana/ui';

import { useDebounce } from 'react-use';

export interface ToolbarProps {
  onAdd?: () => void;
  onSearch?: (search: string) => void;
  onRefresh?: () => void;
  initialSearchQuery?: string;
  placeholder?: string;
  loading?: boolean;
  canAdd?: boolean;
  addText?: string;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  onAdd,
  onSearch,
  onRefresh,
  initialSearchQuery,
  placeholder,
  loading,
  canAdd,
  addText,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery || '');

  useDebounce(
    () => {
      onSearch?.(searchQuery || '');
    },
    300,
    [searchQuery]
  );

  return (
    <div className="page-action-bar" data-testid="users-action-bar">
      <div className="gf-form gf-form--grow">
        <FilterInput
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder={placeholder}
          data-id="search-text-input"
        />
      </div>
      {canAdd && Boolean(onAdd) && (
        <Button onClick={onAdd} data-id="search-add-button">
          {addText || 'Add'}
        </Button>
      )}
      {Boolean(onRefresh) && (
        <Button onClick={onRefresh} icon={loading ? 'fa fa-spinner' : 'sync'} data-id="search-update-input" />
      )}
    </div>
  );
};
