import { Tab, TabsBar } from '@grafana/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationItem } from 'types';
import { SelectNav } from './SelectNav';

interface Props {
  items: NavigationItem[];
}

export const NavigationBar: React.FC<Props> = ({ items }) => {
  const history = useHistory();

  return (
    <>
      {items.length > 1 && (
        <nav>
          <SelectNav customCss="page-header__select-nav" items={items} />
          <div className="page-header__tabs">
            <TabsBar>
              {items.map((item) => (
                <Tab
                  key={item.url}
                  icon={item.icon}
                  label={item.label}
                  active={item.active}
                  onChangeTab={() => {
                    history.push(item.url);
                  }}
                />
              ))}
            </TabsBar>
          </div>
        </nav>
      )}
    </>
  );
};
