import { gql } from '@apollo/client';
import {
  VesselGroupFields,
  VesselIdFields,
  VesselMetaFields,
  UserFragment,
  VesselGroupFieldsResult,
  VesselIdFieldsResult,
  VesselMetaFieldsResult,
  UserFragmentResult,
  ApiKeyFragment,
  PreferencesFragment,
  UserAndOtherPreferences,
} from './fragments';

export const GET_USER = gql`
  query GetUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export type GetUserResult = {
  user: UserFragmentResult;
};

export const GET_ALL_USERS = gql`
  query GetUsers {
    users {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

// Type of result from getAllUsers
export type GetAllUsersResult = {
  users: UserFragmentResult[];
};

export const GET_ALL_VESSELGROUPS = gql`
  query GetVesselGroups {
    vesselGroups {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// Type of result from getAllVesselGroups
export type GetAllVesselGroupsResult = {
  vesselGroups: VesselGroupFieldsResult[];
};

export const GET_VESSELGROUPS = gql`
  query GetVesselGroups($ids: [Int]) {
    vesselGroups(where: { id: { in: $ids } }) {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// Type of result from getVesselGroups
export interface GetVesselGroupsResult {
  vesselGroups: VesselGroupFieldsResult[];
}

// Type of variables from getVesselGroups
export interface GetVesselGroupsVariables {
  ids?: number[] | null;
}

export const GET_VESSELS = gql`
  query GetVessels($imo: [String!]!) {
    vessels(where: { imo: { in: $imo } }) {
      ...VesselIdFields
      ...VesselMetaFields
      vesselGroupMemberships {
        vesselGroupId
      }
    }
  }
  ${VesselIdFields}
  ${VesselMetaFields}
`;

export const GET_ALL_VESSELS = gql`
  query GetVessels {
    vessels {
      ...VesselIdFields
      ...VesselMetaFields
      vesselGroupMemberships {
        vesselGroupId
      }
    }
  }
  ${VesselIdFields}
  ${VesselMetaFields}
`;

// Type of result from getVessels
export interface GetAllVesselsResult {
  vessels: Array<
    VesselIdFieldsResult & VesselMetaFieldsResult & { vesselGroupMemberships: Array<{ vesselGroupId: number }> }
  >;
}

// Type of variables from getVessels
export interface GetVesselsVariables {
  imo: string[];
}

export const GET_VESSELGROUPS_WHERE = gql`
  query GetVesselGroupsWhere($where: VesselGroupWhere) {
    vesselGroups(where: $where) {
      ...VesselGroupFields
    }
  }
  ${VesselGroupFields}
`;

// Type of result from getVesselGroupsWhere
export interface GetVesselGroupsWhereResult {
  vesselGroups: VesselGroupFieldsResult[];
}

// Type of variables from getVesselGroupsWhere
export interface GetVesselGroupsWhereVariables {
  where?: {
    id?: number;
    name?: string;
    description?: string;
  };
}

export const GET_ALL_APIKEYS = gql`
  query {
    apiKeys(where: { deleted: { eq: false } }) {
      ...ApiKeyFragment
    }
  }
  ${ApiKeyFragment}
`;

export const GET_UNITS = gql`
  query {
    units {
      id
      dimension
      baseUnit
      name
      symbol
      factor
      offset
      decimals
    }
  }
`;

export enum DimensionEnum {
  UNDEFINED,
  TEMPERATURE,
  MASS,
  VOLUME,
  TIME,
  LENGTH,
  PRESSURE,
  MASSFLOW,
  DENSITY,
  ANGLE,
  RATIO,
  WORK,
  MASS_PR_DISTANCE,
  MASS_PR_TRANSPORT_WORK,
  TRANSPORT_WORK,
  ROTATIONAL_SPEED,
  FREQUENCY,
  SPEED,
  VOLUMEFLOW,
  KINEMATIC_VISCOSITY,
  FACTOR,
  COEFFICIENT,
  POWER,
  TORQUE,
  VOLUME_PR_DISTANCE,
  VOLTAGE,
  CURRENT,
}

export type Dimension = keyof typeof DimensionEnum;

export type Unit = {
  id: number;
  dimension: Dimension;
  baseUnit: boolean;
  name: string;
  symbol: string;
  factor: number;
  offset: number;
  decimals: number;
};

export type UnitWithCategory = Unit & {
  category: string | null;
};

export type GetUnitsResult = {
  units: Unit[];
};

export const GET_USER_PREFERENCES = gql`
  query {
    preferences {
      ...PreferencesFragment
    }
    userPreferences {
      ...PreferencesFragment
    }
  }
  ${PreferencesFragment}
`;

export const GET_ACTUAL_UNITS = gql`
  query GetActualUnits($unitCategories: [UnitCategoryInput!]!) {
    actualUnits(input: { unitCategories: $unitCategories }) {
      id
      dimension
      category
      baseUnit
      name
      symbol
      factor
      offset
      decimals
    }
  }
`;

export type GetActualUnitsResult = {
  actualUnits: UnitWithCategory[];
};

export type GetActualUnitsVariables = {
  unitCategories: Array<{ dimension: Dimension; category: string | null }>;
};

export type GetPreferencesResult = {
  preferences: UserAndOtherPreferences;
};

export type GetUserPreferencesResult = {
  preferences: UserAndOtherPreferences;
  userPreferences: UserAndOtherPreferences;
};
