import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import { ApiKeysList } from 'components/apikey/ApiKeysList';
import { ApiKeysToolbar } from 'components/apikey/ApiKeysToolbar';
import { ApiKeyResult } from 'graphql/mutations';
import { GET_ALL_APIKEYS } from 'graphql/queries';
import { usePaging } from 'ecomate-hooks';
import { Alert, HorizontalGroup, Pagination, RadioButtonGroup } from '@grafana/ui';

export const ApiKeysPage = () => {
  const { data: response, refetch } = useQuery<ApiKeyResult>(GET_ALL_APIKEYS);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const { entities, page, setPage, totalPages } = usePaging({
    entities: response?.apiKeys,
    pageSize,
    searchQuery,
    options: {
      keys: ['name'],
      sort: (a, b) => a.name?.localeCompare(b.name || '') || 0,
    },
  });

  return (
    <>
      <Alert severity="info" title="API Access">
        To view API documentation, go to:{' '}
        <a href="https://api.ecomate.cloud/data/swagger" target="_blank" rel="noreferrer">
          https://api.ecomate.cloud/data/swagger
        </a>
      </Alert>
      <ApiKeysToolbar
        toolbarProps={{
          onSearch: setSearchQuery,
          initialSearchQuery: '',
          placeholder: 'Search API keys by name',
          canAdd: true,
        }}
        refetch={() => refetch()}
      />
      {response && <ApiKeysList apiKeys={entities} refetch={() => refetch()} />}
      <HorizontalGroup justify="space-between" align="flex-start">
        {response && response?.apiKeys.length > 10 && (
          <HorizontalGroup>
            <span>Page size:</span>
            <RadioButtonGroup
              options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
              onChange={setPageSize}
              value={pageSize}
              size="sm"
            />
          </HorizontalGroup>
        )}
        <Pagination numberOfPages={totalPages} currentPage={page} onNavigate={setPage} hideWhenSinglePage />
      </HorizontalGroup>
    </>
  );
};
