import { SelectableValue } from '@grafana/data';
import { Button, ClipboardButton, ConfirmModal, HorizontalGroup, RadioButtonGroup, Select, Tooltip } from '@grafana/ui';
import { getContextSrv } from 'ecomate-hooks';
import React, { useState } from 'react';
import { User, UserRole, Vessel } from 'types';

export interface UsersTableProps {
  users: User[];
  onRoleChange: (user: User, role: UserRole) => void;
  onRemoveUser: (user: User) => void;
  onShowVessels: (user: User) => void;
  onShowGroups: (user: User) => void;
  onSetPageSize?: (pageSize: number) => void;
  pageSize?: number;
}

export const Roles: Array<SelectableValue<UserRole>> = [
  {
    label: 'Administrator',
    value: 'ADMINISTRATOR',
    description: 'This user can modify access for other users, and view all vessels',
  },
  { label: 'Viewer', value: 'VIEWER', description: 'This user can view all vessels' },
  {
    label: 'Restricted viewer',
    value: 'RESTRICTED_VIEWER',
    description: 'This user can only view specified vessels and groups',
  },
];

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const currentUser = getContextSrv()?.user;
  const { users, onRoleChange, onRemoveUser, onShowVessels, onShowGroups, onSetPageSize, pageSize } = props;
  const [userToRemove, setUserToRemove] = useState<User | null>(null);

  return (
    <>
      {onSetPageSize && (
        <HorizontalGroup>
          <span>Page size:</span>
          <RadioButtonGroup
            options={[10, 20, 30].map((v) => ({ label: v.toString(), value: v }))}
            onChange={onSetPageSize}
            value={pageSize}
            size="sm"
          />
        </HorizontalGroup>
      )}
      <table className="filter-table form-inline" style={{ marginBottom: '4px' }}>
        <thead>
          <tr>
            <th />
            <th>Login</th>
            <th>Seen</th>
            <th>Vessels</th>
            <th>Role</th>
            <th style={{ width: '34px' }} />
            <th style={{ width: '34px' }} />
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            const allVessels: Vessel[] = [
              ...user.vessels,
              ...user.vesselGroups.flatMap((group) => group.vessels),
            ].reduce((acc, vessel) => {
              if (!acc.find((v) => v.id === vessel.id)) {
                acc.push(vessel);
              }
              return acc;
            }, [] as Vessel[]);

            const vesselString = user.role === 'RESTRICTED_VIEWER' ? allVessels.map((e) => e.name).join(', ') : 'All';
            return (
              <tr key={`${user.id}-${index}`}>
                <td className="width-2 text-center">
                  <img className="filter-table__avatar" src={user.avatarUrl} />
                </td>
                <td className="width-6">
                  <span className="ellipsis" title={user.login}>
                    {user.login}
                  </span>
                </td>
                <td className="width-1">
                  {user.lastSeenAtAge ||
                    (user.inviteUrl && (
                      <Tooltip
                        content={
                          'Copy invitation url to clipboard. This is a unique url for the user to complete their registration'
                        }
                      >
                        <ClipboardButton icon="copy" variant="secondary" size="sm" getText={() => user.inviteUrl}>
                          Copy Invite
                        </ClipboardButton>
                      </Tooltip>
                    ))}
                </td>
                <td className="max-width-8">
                  <span className="ellipsis" title={vesselString}>
                    {vesselString}
                  </span>
                </td>
                <td className="max-width-4">
                  <Select
                    options={Roles}
                    value={Roles.find((e) => e.value === user.role)}
                    onChange={(e) => e.value && onRoleChange(user, e.value)}
                  />
                </td>
                <td>
                  {Boolean(user.role === 'RESTRICTED_VIEWER') && (
                    <HorizontalGroup>
                      <Button
                        variant="secondary"
                        size="sm"
                        aria-label="View vessels"
                        onClick={() => onShowVessels(user)}
                      >
                        Vessels
                      </Button>
                      <Button variant="secondary" size="sm" aria-label="View groups" onClick={() => onShowGroups(user)}>
                        Groups
                      </Button>
                    </HorizontalGroup>
                  )}
                </td>
                <td>
                  {currentUser && user.grafanaUserId !== currentUser.id && (
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setUserToRemove(user);
                      }}
                      icon="times"
                      aria-label="Remove user"
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {Boolean(userToRemove) && (
        <ConfirmModal
          body={`Are you sure you want to delete user ${userToRemove?.login}?`}
          confirmText="Delete"
          title="Delete user"
          onDismiss={() => {
            setUserToRemove(null);
          }}
          isOpen={true}
          onConfirm={() => {
            if (!userToRemove) {
              return;
            }
            onRemoveUser(userToRemove);
            setUserToRemove(null);
          }}
        />
      )}
    </>
  );
};
