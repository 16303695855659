import React, { useState } from 'react';
import { Button, Field, Form, HorizontalGroup, InputControl, Modal, MultiSelect } from '@grafana/ui';
import { isDefined, Vessel } from 'types';
import { SelectableValue } from '@grafana/data';
import { useQuery } from '@apollo/client';
import { GET_ALL_VESSELS, GetAllVesselsResult } from 'graphql/queries';
import { Toolbar, ToolbarProps } from './Toolbar';
import { VesselIdFieldsResult } from 'graphql/fragments';

interface Props extends Omit<ToolbarProps, 'onAdd'> {
  onAdd: (vessels: Array<Partial<Vessel>>) => void;
  existingVessels?: VesselIdFieldsResult[];
}

export const VesselToolbar: React.FC<Props> = (props) => {
  const { onAdd, existingVessels } = props;
  const allVessels = useQuery<GetAllVesselsResult>(GET_ALL_VESSELS);

  const [vesselToAdd, setVesselToAdd] = useState<Partial<Vessel> | null>(null);

  return (
    <>
      <Toolbar {...props} onAdd={() => setVesselToAdd({})} addText="Add vessel" />
      {Boolean(vesselToAdd) && (
        <Modal title="Add vessel" isOpen onDismiss={() => setVesselToAdd(null)}>
          <Form
            onSubmit={({ vessels }: { vessels: Array<SelectableValue<Partial<Vessel>>> }) => {
              if (vessels && vessels.length) {
                onAdd(vessels.map((e) => e.value).filter(isDefined));
              }
              setVesselToAdd(null);
            }}
          >
            {({ register, control, errors }) => (
              <>
                <Field label="Vessel">
                  <InputControl
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        placeholder="Select vessel"
                        options={allVessels.data?.vessels
                          .filter((vessel) => !existingVessels?.find((e) => e.id === vessel.id))
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map(
                            (e) => ({ label: e.name, value: e, description: e.imo } as SelectableValue<Partial<Vessel>>)
                          )}
                      />
                    )}
                    name="vessels"
                    control={control}
                  />
                </Field>
                <HorizontalGroup align="flex-end" spacing="md">
                  <Button type="submit">Add</Button>
                </HorizontalGroup>
              </>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
